/*!
 * Author: Theme designed and developed by Michael Palmer, MP Web.
 * Website: https://www.michaelpalmerwebdesign.com.
 * Copyright MP Web 2018.
 * Theme website: https://www.brutalist.design.
 */
/*!
 * [Brutal Site Template - Table of contents]
 * 1. Bootstrap
 * 2. html
 * 3. body
 * 4. Fonts
 * 5. Typography
 * 6. Buttons
 * 7. Lists
 * 8. Inputs
 * 9. Anchors
 * 10. Navigation | nav
 * 11. Fullscreen Hero section | #hero
 * 12. Features | #features
 * 13. Services | #services
 * 14. Pricing | #pricing
 * 15. Gallery | #gallery
 * 16. Video | #video
 * 17. Products | #products
 * 18. Team | #team
 * 19. Contact | #contact
 * 20. Preloader | .preloader
 * 21. Footer | footer
 * 22. Navigation fullscreen | .nav-fs
 * 23. Fullscreen section | #fullscreen
 * 24. Coming soon | .fs-coming-soon
 * 25. Coming soon countdown | .coming-soon-countdown
 * 26. Coming soon background | .coming-soon-background
 * 27. Coming soon background and countdown | .coming-soon-background
 */
/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,400,900");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(255, 255, 255, 0.01) 0 0 1px; }

/*!
 * [Fonts - Table of contents]
 * 1. Import font from Google fonts | Titillium Web
 * 2. Set font family to Titillium Web | h1, h2, h3, h4, h5, p, label, button, li, a, input, textarea
 */
h1, h2, h3, h4, h5, p, span, label, button, li, a, input, textarea, .owl-nav > div {
  font-family: "Titillium Web", sans-serif; }

/*!
 * [Typography - Table of contents]
 * 1. Base font size | 16px
 * 2. Heading 1 | 3.998em
 * 3. Heading 2 | 2.827em
 * 4. Heading 3 | 1.999em
 * 5. Heading 4 | 1.414em
 * 6. Heading 5 | 19px
 * 6. Small font size | 14px
 */
html {
  font-size: 16px; }

h1, h2, h3, h4, h5 {
  display: inline-block;
  margin: 0;
  text-transform: uppercase;
  font-style: normal; }

h1 {
  font-size: 3.998em; }

h2 {
  font-size: 3.998em; }

h3 {
  font-size: 2.827em; }

h4 {
  font-size: 1.999em; }

p, ul li, label, input, textarea, strong, button {
  font-size: 1em; }

p {
  margin: 0; }

/*!
 * [Buttons - Table of contents]
 * 1. Button
 * 2. .btn
 */
.btn, button {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: transparent;
  border: none !important;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none; }
  .btn:focus, button:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

/*!List styles reset*/
ul {
  list-style: none;
  padding: 0;
  margin: 0; }

/*!
 * [Inputs - Table of contents]
 * 1. Input
 * 2. Textarea
 * 3. Select
 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
  margin: 0; }

input, textarea, select {
  font-size: 1em;
  margin: 0;
  padding: 0;
  border: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important; }

/*!
 * [Anchors - Table of contents]
 * 1. Main anchor class | .btl-anchor
 */
a.btl-anchor {
  position: relative;
  display: inline-block; }
  a.btl-anchor:hover:before {
    height: 3px; }
  a.btl-anchor:before {
    content: '';
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
    transition: height 0.3s ease-in-out; }

html, body {
  height: auto; }

/*!
 * [Main Navigation - Table of contents]
 * 1. Nav
 * 2. H1
 * 3. Nav items | ul.navlist
 * 4. Contact | a.contact
 */
nav h1 {
  font-size: 100px;
  line-height: calc(150px + 1px *2);
  width: 100%; }
  nav h1 a {
    display: inline-block;
    width: 100%; }
  nav h1 span.stretch_it {
    display: inline-block; }
  @media (max-width: 575.98px) {
    nav h1 {
      position: relative;
      left: -6px; } }
  @media (max-width: 320px) {
    nav h1 {
      font-size: 82px;
      left: -3px; } }

nav ul.navlist {
  height: 100%;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%; }
  nav ul.navlist li {
    height: calc(150px / 3);
    line-height: calc(150px / 3);
    padding: 0 15px;
    box-sizing: content-box;
    width: 100%;
    border-bottom: solid 1px #000;
    text-transform: uppercase; }
    nav ul.navlist li:last-child {
      border-bottom: transparent; }
    nav ul.navlist li a {
      display: block; }

nav div.anchor:hover a {
  color: #fff !important; }

@media (max-width: 575.98px) {
  nav div.anchor {
    border-top: transparent; }
    nav div.anchor.brand {
      border-bottom: solid 1px #000; } }

nav a.contact {
  display: inline-block;
  width: 100%;
  height: calc(150px + 1px *2);
  line-height: calc(150px + 1px *2);
  text-transform: uppercase; }
  @media (min-width: 768px) {
    nav a.contact {
      text-align: center; } }
  @media (max-width: 575.98px) {
    nav a.contact {
      height: calc(150px / 3);
      line-height: calc(150px / 3); } }
  @media (max-width: 320px) {
    nav a.contact {
      text-align: left; } }

/*!
 * [Secondary Navigation - Table of contents]
 * 1. Nav | .nav-fs
 * 2. Nav items | ul.navlist
 * 3. Contact | a.contact
 * 4. Branding | .brand
 */
nav.nav-fs {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  background: #000; }
  nav.nav-fs div.navigation {
    padding: 0; }
    @media (max-width: 767.98px) {
      nav.nav-fs div.navigation {
        display: none; } }
  nav.nav-fs ul.navlist {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
    nav.nav-fs ul.navlist li {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: calc(100% / 4);
      border-bottom: transparent;
      border-right: solid 1px #000;
      text-transform: uppercase;
      display: inline-block;
      text-align: center; }
      nav.nav-fs ul.navlist li:last-child {
        border-right: transparent; }
      @media (max-width: 767.98px) {
        nav.nav-fs ul.navlist li {
          font-size: 0.875em;
          padding: 0; } }
      nav.nav-fs ul.navlist li:hover {
        background: #fff; }
        nav.nav-fs ul.navlist li:hover a {
          color: #000; }
      nav.nav-fs ul.navlist li a {
        color: #fff; }
  nav.nav-fs a.contact {
    display: inline-block;
    width: auto;
    height: auto;
    text-transform: uppercase;
    text-align: center !important;
    line-height: 45px;
    height: 45px; }
  nav.nav-fs div.anchor.brand {
    position: relative; }
    nav.nav-fs div.anchor.brand:hover {
      background-color: #fff; }
      nav.nav-fs div.anchor.brand:hover a {
        color: #000 !important; }
      nav.nav-fs div.anchor.brand:hover button#mobile-nav {
        color: #000 !important; }
    nav.nav-fs div.anchor.brand a {
      line-height: 45px;
      height: 45px;
      display: inline-block;
      font-size: 1.65em;
      color: #fff; }
      @media (max-width: 767.98px) {
        nav.nav-fs div.anchor.brand a {
          font-size: 1.35em; } }
    nav.nav-fs div.anchor.brand button#mobile-nav {
      color: #fff;
      position: absolute;
      right: 15px;
      top: 10px;
      padding: 0;
      display: none; }
      nav.nav-fs div.anchor.brand button#mobile-nav:focus, nav.nav-fs div.anchor.brand button#mobile-nav:active {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none; }
      @media (max-width: 767.98px) {
        nav.nav-fs div.anchor.brand button#mobile-nav {
          display: block; } }

/*!
 * [Fullscreen hero section - Table of contents]
 * 1. Wrapper | #hero
 */
div#hero h2 {
  margin-bottom: calc(30px / 2);
  display: block; }
  @media (max-width: 575.98px) {
    div#hero h2 span {
      display: none; } }
  @media (max-width: 320px) {
    div#hero h2 {
      font-size: 2.827em; }
      div#hero h2 span {
        white-space: nowrap !important;
        display: inline-block; } }

div#hero h3 {
  text-transform: none;
  display: block;
  text-transform: uppercase; }
  @media (max-width: 320px) {
    div#hero h3 {
      font-size: 1.999em; }
      div#hero h3 span {
        white-space: nowrap !important; } }

div#hero > div {
  border-top: transparent;
  border-bottom: transparent;
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (max-width: 767.98px) {
    div#hero > div {
      border-left: solid 1px #000;
      border-right: solid 1px #000; }
      div#hero > div + div {
        padding-top: 0;
        padding-bottom: 45px; } }
  @media (max-width: 575.98px) {
    div#hero > div {
      border-left: transparent;
      border-right: transparent; } }

div#hero button, div#hero a.cta {
  display: inline-block;
  height: 100%;
  width: 100%;
  width: calc(100% - 30px);
  font-size: 1.414em;
  text-transform: uppercase;
  background: #000;
  color: #fff;
  cursor: pointer; }
  div#hero button br, div#hero a.cta br {
    display: none; }
  @media (max-width: 767.98px) {
    div#hero button, div#hero a.cta {
      padding: 30px;
      width: 100%;
      font-size: 19px; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    div#hero button, div#hero a.cta {
      font-size: 19px; }
      div#hero button br, div#hero a.cta br {
        display: block; } }
  div#hero button:hover span:after, div#hero a.cta:hover span:after {
    left: 100%; }
  div#hero button span, div#hero a.cta span {
    position: relative;
    display: inline-block;
    overflow: hidden;
    top: calc(50% - 18px); }
    div#hero button span:after, div#hero a.cta span:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
      -webkit-transition: left 0.3s ease-in-out;
      -moz-transition: left 0.3s ease-in-out;
      -o-transition: left 0.3s ease-in-out;
      transition: left 0.3s ease-in-out; }
  @media (max-width: 320px) {
    div#hero button br, div#hero a.cta br {
      display: block; } }

/*!
 * [Features section - Table of contents]
 * 1. Wrapper | #features
 * 2. Title | .features-title
 * 3. Left column | .features-left
 * 4. Right column | .features-right
 */
div#features {
  border-bottom: solid 1px #000; }
  @media (max-width: 575.98px) {
    div#features {
      border-bottom: transparent; } }
  div#features h3 {
    line-height: 90px;
    height: 90px; }
    @media (max-width: 991.98px) {
      div#features h3 {
        line-height: 1.2;
        text-align: center;
        width: 100%;
        padding: 30px 0;
        height: auto; } }
  div#features:hover .features-title > div {
    background: #000; }
    div#features:hover .features-title > div h3 {
      color: #fff; }
  div#features div.features-left.features-left > div.elem-border > div:hover .btn-style-main, div#features div.features-right.features-left > div.elem-border > div:hover .btn-style-main {
    outline: solid 1px #000; }
    div#features div.features-left.features-left > div.elem-border > div:hover .btn-style-main a, div#features div.features-right.features-left > div.elem-border > div:hover .btn-style-main a {
      outline: solid 1px #000; }
  div#features div.features-left.features-right > div:hover .btn-style-main, div#features div.features-right.features-right > div:hover .btn-style-main {
    outline: solid 1px #000; }
    div#features div.features-left.features-right > div:hover .btn-style-main a, div#features div.features-right.features-right > div:hover .btn-style-main a {
      outline: solid 1px #000; }
  div#features div.features-left h4, div#features div.features-right h4 {
    display: block;
    margin-bottom: 15px; }
  div#features div.features-left > .row.elem-border {
    height: 100%; }
  div#features div.features-left .btn-style-main {
    margin-top: 9px; }
    @media (max-width: 767.98px) {
      div#features div.features-left .btn-style-main {
        margin-top: 0; } }
  @media (max-width: 991.98px) {
    div#features div.features-left > .row {
      height: auto; } }
  div#features div.features-left > .row > div {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: transparent;
    border-right: transparent; }
    div#features div.features-left > .row > div + div {
      border-left: solid 1px #000; }
    @media (max-width: 991.98px) {
      div#features div.features-left > .row > div {
        border: solid 1px #000;
        border-bottom: transparent; }
        div#features div.features-left > .row > div + div {
          border-left: transparent; }
        div#features div.features-left > .row > div p {
          margin-bottom: 30px; } }
    @media (max-width: 575.98px) {
      div#features div.features-left > .row > div {
        border-left: transparent;
        border-right: transparent; }
        div#features div.features-left > .row > div + div {
          border-left: transparent; } }
    div#features div.features-left > .row > div:hover {
      cursor: pointer;
      cursor: unset; }
      div#features div.features-left > .row > div:hover a:after {
        left: 100%; }
  div#features div.features-right {
    height: 86%;
    height: calc(100% - 92px); }
    @media (max-width: 991.98px) {
      div#features div.features-right {
        height: auto; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      div#features div.features-right {
        height: calc(100% - 92px); } }
    div#features div.features-right > div {
      position: relative;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: transparent;
      border-bottom: transparent; }
      @media (max-width: 767.98px) {
        div#features div.features-right > div {
          border-bottom: solid 1px #000; } }
      @media (max-width: 575.98px) {
        div#features div.features-right > div {
          border-left: transparent;
          border-right: transparent; }
          div#features div.features-right > div:last-child {
            border-bottom: transparent; } }
      div#features div.features-right > div p {
        margin-bottom: 30px; }
        div#features div.features-right > div p span {
          display: none; }
          @media (max-width: 575.98px) {
            div#features div.features-right > div p span {
              display: inline-block; } }
      div#features div.features-right > div .btn-style-main {
        margin-top: 0; }
      @media (max-width: 320px) {
        div#features div.features-right > div:last-child {
          border-bottom: transparent; } }

/*!
 * [Services section - Table of contents]
 * 1. Wrapper | #services
 * 2. Left column | .services-left
 * 3. Center column | .services-center
 * 4. Right column | .services-right
 */
div#services {
  height: auto; }
  @media (max-width: 991.98px) {
    div#services {
      height: auto; } }
  div#services div.services-center {
    position: relative;
    border-bottom: solid 1px #000;
    border-right: solid 1px #000; }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div#services div.services-center {
        border-right: transparent; } }
    @media (max-width: 991.98px) {
      div#services div.services-center {
        border-top: solid 1px #000;
        border-left: solid 1px #000;
        border-right: solid 1px #000;
        border-bottom: transparent; } }
    @media (max-width: 767.98px) {
      div#services div.services-center {
        border-right: transparent;
        border-left: transparent; } }
    @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
      div#services div.services-center {
        border-right: solid 1px #000; } }
  div#services h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    left: 0;
    width: 100%; }
    @media (max-width: 991.98px) {
      div#services h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div#services h3 br {
          display: none; } }
  div#services:hover .services-center {
    background: #000; }
    div#services:hover .services-center h3 {
      color: #fff; }
  div#services > div {
    border-top: solid 1px #000; }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div#services > div {
        border-top: transparent; } }
    @media (max-width: 767.98px) {
      div#services > div {
        border-top: transparent; } }
  div#services h4 {
    display: block;
    margin-bottom: 15px; }
    div#services h4 br {
      display: none; }
      @media (max-width: 575.98px) {
        div#services h4 br {
          display: block; } }
  div#services a {
    position: relative;
    display: inline-block;
    overflow: hidden; }
    div#services a:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #000;
      -webkit-transition: left 0.3s ease-in-out;
      -moz-transition: left 0.3s ease-in-out;
      -o-transition: left 0.3s ease-in-out;
      transition: left 0.3s ease-in-out; }
  div#services p {
    margin-bottom: 30px;
    width: 50%; }
    @media (min-width: 768px) and (max-width: 1024px) {
      div#services p {
        width: 65%; } }
    @media (max-width: 767.98px) {
      div#services p {
        width: 100%; } }
  @media (max-width: 991.98px) {
    div#services div.services-left, div#services div.services-right {
      height: auto; } }
  div#services div.services-left > div, div#services div.services-right > div {
    cursor: pointer;
    border-top: transparent;
    border-left: solid 1px #000;
    padding-top: 30px;
    padding-bottom: 30px; }
    @media (max-width: 991.98px) {
      div#services div.services-left > div, div#services div.services-right > div {
        border-bottom: transparent;
        border-top: solid 1px #000;
        border-right: solid 1px #000;
        border-right-width: 0.5px; }
        div#services div.services-left > div:last-child, div#services div.services-right > div:last-child {
          border-left-width: 0.5px;
          border-right-width: 1px; } }
    @media (max-width: 767.98px) {
      div#services div.services-left > div, div#services div.services-right > div {
        border-bottom: transparent;
        border-left: transparent;
        border-top: solid 1px #000;
        border-right: solid 1px #000;
        border-right-width: 0.5px; }
        div#services div.services-left > div:last-child, div#services div.services-right > div:last-child {
          border-left: solid 1px #000;
          border-left-width: 0.5px;
          border-right: transparent; } }
    @media (max-width: 320px) {
      div#services div.services-left > div, div#services div.services-right > div {
        border-right: transparent;
        border-left: transparent; }
        div#services div.services-left > div:last-child, div#services div.services-right > div:last-child {
          border-left: transparent; } }
    @media (max-width: 320px) {
      div#services div.services-left > div, div#services div.services-right > div {
        display: block !important;
        width: 100%;
        max-width: none;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%; } }
    @media (max-width: 991.98px) {
      div#services div.services-left > div h4, div#services div.services-right > div h4 {
        height: auto;
        line-height: 45px; } }
    div#services div.services-left > div:hover, div#services div.services-right > div:hover {
      cursor: pointer;
      cursor: unset; }
      div#services div.services-left > div:hover a:after, div#services div.services-right > div:hover a:after {
        left: 100%; }
  div#services div.services-right > div {
    border-left: transparent; }
    @media (max-width: 991.98px) {
      div#services div.services-right > div {
        border-left: solid 1px #000; }
        div#services div.services-right > div:last-child {
          border-right-width: 1px;
          border-left-width: 0.5px; } }
    @media (max-width: 767.98px) {
      div#services div.services-right > div {
        border-left: transparent; }
        div#services div.services-right > div:last-child {
          border-right-width: 1px;
          border-left-width: 0.5px; } }
    @media (max-width: 320px) {
      div#services div.services-right > div {
        border-right: transparent;
        border-left: transparent; } }

/*!
 * [Pricing section - Table of contents]
 * 1. Wrapper | #prices
 * 2. Left column | .prices-left
 * 3. Right column | .prices-right
 */
div#prices {
  height: 240px; }
  @media (max-width: 991.98px) {
    div#prices {
      height: auto; } }
  div#prices div.prices-left {
    position: relative;
    border-right: none; }
  div#prices h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px; }
    @media (max-width: 991.98px) {
      div#prices h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div#prices h3 br {
          display: none; } }
  div#prices:hover .prices-left {
    background: #000; }
    div#prices:hover .prices-left h3 {
      color: #fff; }
  div#prices > div {
    border-top: transparent; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div#prices > div {
        border-top: solid 1px #000;
        border-bottom: transparent; } }
  div#prices div.prices-right {
    border-left: solid 1px #000; }
    @media (max-width: 767px) {
      div#prices div.prices-right {
        border-left: none;
        overflow: hidden; } }
    div#prices div.prices-right > div {
      text-align: center;
      z-index: 1;
      position: relative;
      border-right: solid 1px #000; }
      div#prices div.prices-right > div p {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        max-width: 35%;
        margin: 0 auto;
        padding-bottom: 5px;
        display: inline-block;
        overflow: hidden; }
        div#prices div.prices-right > div p:after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 1px;
          background: #000;
          -webkit-transition: left 0.3s ease-in-out;
          -moz-transition: left 0.3s ease-in-out;
          -o-transition: left 0.3s ease-in-out;
          transition: left 0.3s ease-in-out; }
        @media (max-width: 768px) and (orientation: portrait) {
          div#prices div.prices-right > div p {
            max-width: 100%; } }
        @media (max-width: 991.98px) {
          div#prices div.prices-right > div p {
            position: relative;
            right: initial;
            bottom: initial;
            text-align: center; } }
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          div#prices div.prices-right > div p {
            max-width: 60%;
            bottom: 50px; } }
      div#prices div.prices-right > div h4 {
        height: calc(240px - 1px);
        line-height: calc(240px - 15px);
        font-size: 3.998em; }
        @media (max-width: 991.98px) {
          div#prices div.prices-right > div h4 {
            height: auto;
            line-height: 1em;
            margin: 0 0 15px 0;
            display: block; } }
        @media (max-width: 767.98px) {
          div#prices div.prices-right > div h4 {
            height: auto;
            line-height: 1em;
            margin: 0 0 15px 0;
            display: block; } }
        @media (max-width: 320px) {
          div#prices div.prices-right > div h4 {
            position: relative;
            left: -2px; } }
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          div#prices div.prices-right > div h4 {
            position: relative;
            line-height: 1em;
            top: 75px; } }
      div#prices div.prices-right > div div.prices-wrap {
        position: relative;
        z-index: 2;
        background-color: #fff;
        left: 0;
        right: 0;
        -webkit-transition: left .2s ease-in-out, right .2s ease-in-out;
        -moz-transition: left .2s ease-in-out, right .2s ease-in-out;
        -ms-transition: left .2s ease-in-out, right .2s ease-in-out;
        -o-transition: left .2s ease-in-out, right .2s ease-in-out;
        transition: left .2s ease-in-out, right .2s ease-in-out; }
        div#prices div.prices-right > div div.prices-wrap span {
          color: #fff;
          left: 15px;
          position: absolute;
          top: 50%;
          line-height: 30px;
          margin-top: -15px;
          font-size: 30px; }
          @media (max-width: 767px) {
            div#prices div.prices-right > div div.prices-wrap span {
              display: none; } }
        div#prices div.prices-right > div div.prices-wrap.reveal-left span {
          left: initial;
          right: 15px; }
        @media (max-width: 767px) {
          div#prices div.prices-right > div div.prices-wrap {
            padding: 75px 0; } }
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          div#prices div.prices-right > div div.prices-wrap {
            padding: 90px 0; } }
        @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
          div#prices div.prices-right > div div.prices-wrap {
            padding: 0; } }
      div#prices div.prices-right > div div.prices-details {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 1;
        padding: 45px 15px 30px;
        text-align: left; }
        @media (max-width: 767px) {
          div#prices div.prices-right > div div.prices-details {
            padding: 45px 15px 15px;
            text-align: center; } }
        @media (min-width: 768px) and (max-width: 1024px) {
          div#prices div.prices-right > div div.prices-details {
            padding: 15px; } }
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
          div#prices div.prices-right > div div.prices-details {
            padding-top: 35px; } }
        @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
          div#prices div.prices-right > div div.prices-details {
            padding-top: 35px; } }
        div#prices div.prices-right > div div.prices-details h5 {
          margin-bottom: 15px;
          font-size: 1.414em;
          display: block; }
        div#prices div.prices-right > div div.prices-details p {
          width: 59%;
          max-width: none;
          margin: 0;
          padding: 0;
          top: 0;
          bottom: 0;
          position: relative;
          display: inline-block;
          vertical-align: top; }
          @media (max-width: 768px) and (orientation: portrait) {
            div#prices div.prices-right > div div.prices-details p {
              width: 100%;
              display: block;
              margin-bottom: 10px; } }
          @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
            div#prices div.prices-right > div div.prices-details p {
              text-align: left; } }
          @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            div#prices div.prices-right > div div.prices-details p {
              width: 100%;
              display: block;
              margin-bottom: 10px;
              text-align: left; } }
          @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
            div#prices div.prices-right > div div.prices-details p {
              width: 100%;
              display: block;
              margin-bottom: 10px;
              text-align: left; } }
          div#prices div.prices-right > div div.prices-details p:after {
            display: none; }
        div#prices div.prices-right > div div.prices-details span {
          display: none; }
          @media (max-width: 767px) {
            div#prices div.prices-right > div div.prices-details span {
              left: 15px;
              position: absolute;
              bottom: 15px;
              line-height: 30px;
              font-size: 30px;
              color: #000;
              display: block; } }
        div#prices div.prices-right > div div.prices-details ul {
          display: inline-block;
          vertical-align: top;
          margin-left: 8px;
          padding-left: 7px;
          border-left: solid 1px #000;
          font-size: 0.875em; }
          @media (max-width: 768px) and (orientation: portrait) {
            div#prices div.prices-right > div div.prices-details ul {
              margin-left: 0;
              padding-left: 0;
              border: none; } }
          @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            div#prices div.prices-right > div div.prices-details ul {
              margin-left: 0;
              padding-left: 0;
              border: none; } }
          @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
            div#prices div.prices-right > div div.prices-details ul {
              margin-left: 0;
              padding-left: 0;
              border: none; } }
          div#prices div.prices-right > div div.prices-details ul li {
            margin-bottom: 5px; }
            @media (max-width: 767px) {
              div#prices div.prices-right > div div.prices-details ul li {
                display: inline-block;
                width: auto; } }
            @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
              div#prices div.prices-right > div div.prices-details ul li {
                display: inline-block;
                width: 49%; } }
            @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
              div#prices div.prices-right > div div.prices-details ul li {
                display: inline-block;
                width: auto; } }
      div#prices div.prices-right > div.active .prices-wrap {
        left: calc(100% + 16px);
        width: calc(100% + 30px);
        background-color: #000; }
        div#prices div.prices-right > div.active .prices-wrap * {
          color: #fff; }
        div#prices div.prices-right > div.active .prices-wrap.reveal-left {
          left: initial;
          left: calc(-100% - 45px);
          width: calc(100% + 30px); }
      div#prices div.prices-right > div:last-child {
        border-right: transparent; }
      @media (max-width: 991.98px) {
        div#prices div.prices-right > div {
          border-right: transparent;
          border-left: solid 1px #000; } }
      @media (max-width: 767.98px) {
        div#prices div.prices-right > div {
          border-bottom: solid 1px #000; }
          div#prices div.prices-right > div:last-child {
            border-bottom: transparent; } }
      @media (max-width: 575.98px) {
        div#prices div.prices-right > div {
          border-left: transparent; } }
      div#prices div.prices-right > div:hover {
        cursor: pointer; }
        div#prices div.prices-right > div:hover p:after {
          left: 100%; }

/*!
 * [Gallery section - Table of contents]
 * 1. Wrapper | #gallery
 * 2. Left column | .carousel-wrap
 * 3. Right column | .gallery-right
 * 4. Carousel navigation | .owl-nav
 */
div#gallery {
  height: 450px; }
  @media (max-width: 991.98px) {
    div#gallery {
      height: auto !important; } }
  @media (min-width: 992px) {
    div#gallery {
      height: 600px; } }
  @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    div#gallery {
      border-bottom: solid 1px #000; } }
  div#gallery > div {
    border-top: transparent;
    border-bottom: transparent; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div#gallery > div {
        border-top: solid 1px #000;
        border-bottom: transparent; } }
  div#gallery h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    left: 0;
    width: 100%;
    text-align: center; }
    @media (max-width: 991.98px) {
      div#gallery h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div#gallery h3 br {
          display: none; } }
  div#gallery:hover .gallery-right {
    background: #000; }
    div#gallery:hover .gallery-right h3 {
      color: #fff; }
  @media (max-width: 991.98px) {
    div#gallery .gallery-right {
      border-left: solid 1px #000; } }
  @media (max-width: 767.98px) {
    div#gallery .gallery-right {
      border-left: transparent;
      border-bottom: solid 1px #000; } }
  div#gallery .carousel-wrap {
    padding: 0; }
    div#gallery .carousel-wrap .img-wrap {
      overflow: hidden;
      height: 450px; }
      @media (min-width: 992px) {
        div#gallery .carousel-wrap .img-wrap {
          height: 600px; } }
      @media (max-width: 575.98px) {
        div#gallery .carousel-wrap .img-wrap {
          height: 300px; } }
      div#gallery .carousel-wrap .img-wrap img {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 450px; }
        @media (min-width: 992px) {
          div#gallery .carousel-wrap .img-wrap img {
            height: 600px; } }
        @media (max-width: 575.98px) {
          div#gallery .carousel-wrap .img-wrap img {
            height: 300px; } }
    div#gallery .carousel-wrap .owl-nav {
      padding: 0;
      margin: 0 auto;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%; }
    div#gallery .carousel-wrap .owl-prev, div#gallery .carousel-wrap .owl-next {
      width: auto;
      height: auto;
      line-height: 50px;
      font-size: 30px;
      position: absolute;
      top: 50%;
      display: block !important;
      border: none;
      margin: -15px;
      background: transparent;
      text-transform: uppercase;
      padding: 0 8px;
      background: #fff;
      color: #000;
      filter: alpha(opacity=100);
      -moz-opacity: 1;
      -khtml-opacity: 1;
      opacity: 1;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
      @media (max-width: 575.98px) {
        div#gallery .carousel-wrap .owl-prev, div#gallery .carousel-wrap .owl-next {
          line-height: 40px;
          margin: -20px auto 0;
          font-size: 1.414em; }
          div#gallery .carousel-wrap .owl-prev.owl-prev, div#gallery .carousel-wrap .owl-next.owl-prev {
            left: 0; }
          div#gallery .carousel-wrap .owl-prev.owl-next, div#gallery .carousel-wrap .owl-next.owl-next {
            right: 0; } }
      div#gallery .carousel-wrap .owl-prev:hover, div#gallery .carousel-wrap .owl-next:hover {
        background: #000;
        color: #fff; }
      div#gallery .carousel-wrap .owl-prev.disabled, div#gallery .carousel-wrap .owl-next.disabled {
        filter: alpha(opacity=50);
        -moz-opacity: 0.5;
        -khtml-opacity: 0.5;
        opacity: 0.5; }
        div#gallery .carousel-wrap .owl-prev.disabled:hover, div#gallery .carousel-wrap .owl-next.disabled:hover {
          background: #fff;
          color: #000; }
    div#gallery .carousel-wrap .owl-prev {
      left: 30px; }
    div#gallery .carousel-wrap .owl-next {
      right: 34px; }

/*!
 * [Video section - Table of contents]
 * 1. Wrapper | #video
 * 2. Left column | .video-left
 * 3. Right column | div[id^="video-wrap"]
 */
div[id^="video"] {
  height: 100%; }
  @media (max-width: 991.98px) {
    div[id^="video"] {
      height: auto; } }
  div[id^="video"] > div {
    border-top: transparent; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div[id^="video"] > div {
        border-top: solid 1px #000;
        border-bottom: transparent; } }
  div[id^="video"] h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    position: relative;
    left: 0;
    width: 100%;
    text-align: center; }
    @media (max-width: 991.98px) {
      div[id^="video"] h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div[id^="video"] h3 br {
          display: none; } }
  div[id^="video"]:hover div.video-left {
    background: #000; }
    div[id^="video"]:hover div.video-left h3 {
      color: #fff; }
  div[id^="video"] div[id^="video-wrap"] {
    position: relative;
    padding: 0;
    overflow: hidden;
    text-align: center; }
    div[id^="video"] div[id^="video-wrap"] img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
      min-height: 0;
      min-width: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2; }
      @media (max-width: 991.98px) {
        div[id^="video"] div[id^="video-wrap"] img {
          height: auto; } }
    div[id^="video"] div[id^="video-wrap"] p {
      line-height: 50px;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      display: inline-block;
      border: none;
      margin: -25px auto 0;
      background: transparent;
      text-transform: uppercase;
      z-index: 3;
      width: auto;
      max-width: 90px;
      text-align: center;
      cursor: pointer;
      padding: 0 8px;
      background: #fff;
      color: #000;
      filter: alpha(opacity=100);
      -moz-opacity: 1;
      -khtml-opacity: 1;
      opacity: 1;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -o-border-radius: 0;
      border-radius: 0; }
      @media (max-width: 575.98px) {
        div[id^="video"] div[id^="video-wrap"] p {
          max-width: 70px;
          margin: 0 auto;
          font-size: 1.414em;
          top: 50%;
          margin-top: -25px; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        div[id^="video"] div[id^="video-wrap"] p {
          max-width: 120px; } }
      div[id^="video"] div[id^="video-wrap"] p:hover {
        background: #000;
        color: #fff; }
    div[id^="video"] div[id^="video-wrap"] .owl-carousel .item-wrap {
      position: relative; }
      div[id^="video"] div[id^="video-wrap"] .owl-carousel .item-wrap:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%; }
      div[id^="video"] div[id^="video-wrap"] .owl-carousel .item-wrap > .item-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }

/*!
 * [Products section - Table of contents]
 * 1. Wrapper | #products
 * 2. Title | .features-title
 * 3. Left column | .products-left
 * 4. Center column | .products-center
 * 5. Right column | .products-right
 * 6. Product container | .product-wrap
 */
div#products {
  border-left: solid 1px #000;
  border-right: solid 1px #000; }
  @media (max-width: 767px) {
    div#products {
      border-left: none;
      border-right: none;
      border-top: solid 1px #000; } }
  div#products h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    width: 100%;
    left: 0; }
    @media (max-width: 991.98px) {
      div#products h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div#products h3 br {
          display: none; } }
  @media (max-width: 768px) and (orientation: portrait) {
    div#products div.products-title-carousel {
      display: none; } }
  div#products div.products-title-mobile {
    display: none; }
    @media (max-width: 768px) and (orientation: portrait) {
      div#products div.products-title-mobile {
        display: block; } }
  div#products div.products-center {
    position: relative;
    text-align: center;
    padding: 0;
    border-bottom: solid 1px #000; }
  div#products:hover .products-center {
    background: #000; }
    div#products:hover .products-center h3 {
      color: #fff; }
  div#products .products-center:hover .products-left-outer div.product-wrap,
  div#products .products-center:hover .products-right-outer div.product-wrap {
    position: relative; }
    div#products .products-center:hover .products-left-outer div.product-wrap:before,
    div#products .products-center:hover .products-right-outer div.product-wrap:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background: rgba(255, 255, 255, 0.35) !important; }
  div#products .products-left-outer:hover div.product-wrap, div#products .products-right-outer:hover div.product-wrap {
    position: relative; }
    div#products .products-left-outer:hover div.product-wrap:before, div#products .products-right-outer:hover div.product-wrap:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background: rgba(255, 255, 255, 0.35); }
  @media (max-width: 768px) and (orientation: portrait) {
    div#products .products-left-outer, div#products .products-right-outer {
      display: none; } }
  div#products > div {
    border: none; }
    div#products > div .row > div {
      padding: 0;
      height: auto;
      text-align: center; }
      @media (min-width: 1500px) {
        div#products > div .row > div {
          height: auto; } }
      @media (max-width: 991.98px) {
        div#products > div .row > div {
          height: auto; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        div#products > div .row > div {
          height: auto;
          border-bottom-width: 0.5px;
          border-bottom: transparent; } }
      @media (max-width: 767.98px) {
        div#products > div .row > div {
          height: auto; } }
      @media (max-width: 320px) {
        div#products > div .row > div {
          height: auto !important; } }
      div#products > div .row > div .product-wrap {
        cursor: pointer; }
        div#products > div .row > div .product-wrap img {
          width: 100%;
          height: auto;
          position: relative;
          outline: solid 1px #000; }
          @media (min-width: 1500px) {
            div#products > div .row > div .product-wrap img {
              height: auto; } }
          @media (min-width: 768px) and (max-width: 991.98px) {
            div#products > div .row > div .product-wrap img {
              height: auto;
              width: 100%; } }
          @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            div#products > div .row > div .product-wrap img {
              height: auto;
              vertical-align: top; } }
          @media (max-width: 767.98px) {
            div#products > div .row > div .product-wrap img {
              width: 100%;
              height: auto;
              padding: 0;
              vertical-align: top; } }
      @media (max-width: 991.98px) {
        div#products > div .row > div {
          border-right: transparent;
          border-left: solid 1px #000; }
          div#products > div .row > div:last-child {
            border-right: solid 1px #000; }
          div#products > div .row > div + div {
            border-left: solid 1px #000; } }
      @media (max-width: 575.98px) {
        div#products > div .row > div {
          border-left: transparent;
          border-bottom: solid 1px #000; } }
    div#products > div .row + .row img {
      position: relative;
      top: -1px; }
    div#products > div div.product-wrap {
      position: relative; }
      div#products > div div.product-wrap:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: rgba(255, 255, 255, 0); }
      div#products > div div.product-wrap:hover:before {
        background: rgba(255, 255, 255, 0) !important; }
    div#products > div .products-right > div {
      border-right: transparent; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div#products > div .products-left > div {
        height: calc(auto + 30px * 2 + 1px); } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div#products > div .products-left + .products-left > div {
        height: calc(auto + 30px * 2); } }
    div#products > div .products-left > div:last-child {
      border-right: transparent; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        div#products > div .products-left > div:last-child {
          border-right: solid 1px #000; } }
    div#products > div .row:last-child > div {
      border-bottom: transparent; }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        div#products > div .row:last-child > div {
          border-top: solid 1px #000;
          border-top-width: 0.5px; } }
      @media (max-width: 767.98px) {
        div#products > div .row:last-child > div {
          border-bottom: solid 1px #000; } }
  div#products div.products-carousel {
    width: 100%;
    position: relative;
    z-index: 50; }
    @media (max-width: 768px) and (orientation: portrait) {
      div#products div.products-carousel {
        border-top: solid 1px #000; } }
    div#products div.products-carousel, div#products div.products-carousel .owl-stage-outer, div#products div.products-carousel .owl-stage, div#products div.products-carousel .owl-item, div#products div.products-carousel .product-carousel-item {
      height: 100%; }
      @media (max-width: 767px) {
        div#products div.products-carousel, div#products div.products-carousel .owl-stage-outer, div#products div.products-carousel .owl-stage, div#products div.products-carousel .owl-item, div#products div.products-carousel .product-carousel-item {
          height: 400px; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        div#products div.products-carousel, div#products div.products-carousel .owl-stage-outer, div#products div.products-carousel .owl-stage, div#products div.products-carousel .owl-item, div#products div.products-carousel .product-carousel-item {
          height: 450px; } }
      @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
        div#products div.products-carousel, div#products div.products-carousel .owl-stage-outer, div#products div.products-carousel .owl-stage, div#products div.products-carousel .owl-item, div#products div.products-carousel .product-carousel-item {
          height: 100%; } }
    div#products div.products-carousel div.product-carousel-item {
      position: relative;
      background-color: #fff;
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: -1px; }
      div#products div.products-carousel div.product-carousel-item .product-description {
        position: absolute;
        left: 0;
        right: 0;
        text-align: left;
        bottom: 0;
        padding: 15px;
        background: rgba(255, 255, 255, 0.85);
        width: 65%; }
        @media (max-width: 768px) and (orientation: portrait) {
          div#products div.products-carousel div.product-carousel-item .product-description {
            width: 100%;
            height: 100%;
            background: transparent;
            padding-bottom: 45px;
            top: 0; } }
      div#products div.products-carousel div.product-carousel-item a.product-link {
        display: none;
        position: absolute;
        right: 15px;
        bottom: 0;
        font-size: 30px; }
      div#products div.products-carousel div.product-carousel-item h5 {
        color: #000;
        margin-bottom: 8px; }
        @media (max-width: 768px) and (orientation: portrait) {
          div#products div.products-carousel div.product-carousel-item h5 {
            padding: 3px 5px;
            display: inline-block;
            background: #fff; } }
      div#products div.products-carousel div.product-carousel-item p {
        margin: 0; }
        @media (max-width: 768px) and (orientation: portrait) {
          div#products div.products-carousel div.product-carousel-item p {
            position: absolute;
            bottom: 45px;
            background: rgba(255, 255, 255, 0.85);
            padding: 5px 8px;
            width: calc(100% - 30px);
            left: 15px;
            display: inline-block; } }
      div#products div.products-carousel div.product-carousel-item .btn-style-main {
        bottom: 0;
        width: 80%;
        margin-left: 10%; }
      div#products div.products-carousel div.product-carousel-item img {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    div#products div.products-carousel div.owl-nav {
      position: absolute;
      bottom: 0;
      right: 10px;
      margin: 0; }
      @media (max-width: 768px) and (orientation: portrait) {
        div#products div.products-carousel div.owl-nav {
          left: 0;
          right: 0;
          bottom: 7px;
          text-align: center; } }
      div#products div.products-carousel div.owl-nav > div {
        background: transparent;
        margin: 0;
        font-size: 30px;
        filter: alpha(opacity=100);
        -moz-opacity: 1;
        -khtml-opacity: 1;
        opacity: 1;
        color: #fff;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0; }
        @media (max-width: 768px) and (orientation: portrait) {
          div#products div.products-carousel div.owl-nav > div {
            line-height: 30px;
            height: 30px; }
            div#products div.products-carousel div.owl-nav > div span {
              line-height: 19px;
              display: block;
              height: 30px; } }
        div#products div.products-carousel div.owl-nav > div:hover {
          background: #fff;
          color: #000; }

/*!
 * [Team section - Table of contents]
 * 1. Wrapper | #team
 * 2. Left column | .team-left
 * 3. Content wrapper | .team-member
 * 4. Content container | .team-wrap
 */
div#team {
  height: auto; }
  @media (max-width: 991.98px) {
    div#team {
      height: auto; } }
  div#team h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 15px;
    left: 0;
    width: 100%;
    text-align: center; }
    @media (max-width: 991.98px) {
      div#team h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div#team h3 br {
          display: none; } }
  div#team > div {
    border-top: transparent; }
    @media (max-width: 991.98px) {
      div#team > div {
        border: none;
        border-top: solid 1px #000; } }
  div#team:hover .team-left {
    background: #000; }
    div#team:hover .team-left h3 {
      color: #fff; }
  div#team div.team-left {
    position: relative;
    border-right: transparent; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div#team div.team-left {
        border-left: solid 1px #000;
        border-right: solid 1px #000; } }
  div#team div.team-carousel-wrap {
    padding: 0; }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div#team div.team-carousel-wrap {
        border-right: solid 1px #000; } }
    div#team div.team-carousel-wrap div.team-carousel-nav {
      height: 60px;
      position: relative;
      border-top: solid 1px #000;
      border-left: solid 1px #000; }
    div#team div.team-carousel-wrap div.team-carousel .owl-nav {
      position: absolute;
      bottom: -60px;
      width: 100%;
      margin: 0; }
      div#team div.team-carousel-wrap div.team-carousel .owl-nav > div {
        background: transparent;
        margin: 0;
        padding: 0 15px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0; }
        div#team div.team-carousel-wrap div.team-carousel .owl-nav > div > span {
          font-size: 30px;
          line-height: 60px;
          color: #000; }
        div#team div.team-carousel-wrap div.team-carousel .owl-nav > div:hover {
          background: #000 !important; }
          div#team div.team-carousel-wrap div.team-carousel .owl-nav > div:hover span {
            color: #fff; }
  div#team div.team-member {
    border-left: solid 1px #000; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      div#team div.team-member:last-child {
        border-right: solid 1px #000; } }
    @media (max-width: 767px) {
      div#team div.team-member {
        border-left: none; } }
  div#team div.team-wrap:hover p > span {
    background: #000;
    color: #fff; }
  div#team div.team-wrap p {
    position: relative;
    width: 100%;
    height: calc(240px - 1px);
    margin-left: 0; }
    div#team div.team-wrap p > span {
      position: absolute;
      top: 84px;
      left: -85px;
      text-transform: uppercase;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transform-origin: bottom;
      -moz-transform-origin: bottom;
      -o-transform-origin: bottom;
      transform-origin: bottom;
      border-bottom: solid 1px #000;
      width: 240px;
      padding: 5px 0 5px;
      text-align: center;
      display: block; }
      @media (max-width: 991.98px) {
        div#team div.team-wrap p > span {
          border-top: solid 1px #000; } }
  div#team div.team-wrap div {
    position: absolute;
    top: 45%;
    border: solid 1px #000;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    width: 150px;
    height: 150px;
    display: inline-block;
    left: 35px;
    right: 0;
    margin: -75px auto 0;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      div#team div.team-wrap div {
        left: 35px; } }
    div#team div.team-wrap div img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: auto;
      position: relative;
      top: -1px; }
      @media (max-width: 991.98px) {
        div#team div.team-wrap div img {
          width: 100%;
          height: auto;
          top: 0; } }
  div#team div.team-wrap span.team-member-name {
    position: absolute;
    display: block;
    width: calc(100% - 35px);
    text-align: center;
    bottom: 15px;
    left: 35px; }

/*!
 * [Contact section - Table of contents]
 * 1. Wrapper | #contact
 * 2. Left column | ul#social
 * 3. Right column | .contact-right
 * 4. Title | .contact-title
 * 5. Contact form | form
 */
div#contact {
  height: auto; }
  @media (max-width: 991.98px) {
    div#contact {
      height: auto; } }
  div#contact h3 {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    text-align: center; }
    @media (max-width: 767.98px) {
      div#contact h3 {
        position: relative;
        top: initial;
        left: initial;
        width: 100%;
        text-align: center;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        transform: none;
        padding: 30px 0; }
        div#contact h3 br {
          display: none; } }
  div#contact > div {
    border-top: transparent; }
    @media (max-width: 991.98px) {
      div#contact > div {
        border: none;
        border-top: solid 1px #000; } }
  div#contact ul#social {
    height: 100%;
    margin-left: -15px;
    margin-right: -15px;
    width: 100%; }
    @media (max-width: 991.98px) {
      div#contact ul#social {
        width: calc(100% + 30px); } }
    @media (max-width: 767.98px) {
      div#contact ul#social {
        margin: 0;
        width: 100%; } }
    div#contact ul#social li {
      height: calc(100% / 3 - 1px / 2);
      padding: 0 calc(30px / 2);
      box-sizing: content-box;
      width: 100%;
      border-bottom: solid 1px #000;
      font-size: 1.414em;
      cursor: pointer; }
      div#contact ul#social li:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle; }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        div#contact ul#social li {
          height: auto;
          line-height: initial;
          padding: calc(30px / 2) 30px;
          width: 100%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-left: solid 1px #000;
          border-right: solid 1px #000; } }
      @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
        div#contact ul#social li {
          height: calc(100% / 3 - 1px / 2);
          padding: 0 calc(30px / 2);
          box-sizing: content-box;
          width: 100%;
          border-bottom: solid 1px #000;
          font-size: 1.414em;
          cursor: pointer; } }
      @media (max-width: 767.98px) {
        div#contact ul#social li {
          height: 60px;
          line-height: 60px;
          width: calc(100% / 3);
          display: inline-block;
          vertical-align: top;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          -o-box-sizing: border-box;
          box-sizing: border-box;
          text-align: center;
          border-right: solid 1px #000;
          border-bottom: transparent; }
          div#contact ul#social li:last-child {
            border-right: transparent; } }
      @media (max-width: 575.98px) {
        div#contact ul#social li {
          font-size: 19px;
          height: auto;
          line-height: 45px;
          width: 100%;
          display: block;
          border-right: transparent;
          border-bottom: solid 1px #000; }
          div#contact ul#social li:last-child {
            border-bottom: transparent; } }
      div#contact ul#social li:hover {
        background: #000;
        color: #fff; }
      div#contact ul#social li:last-child {
        border-bottom: transparent; }
        @media (max-width: 991.98px) {
          div#contact ul#social li:last-child {
            border-bottom: solid 1px #000; } }
        @media (max-width: 767.98px) {
          div#contact ul#social li:last-child {
            border-bottom: transparent; } }
  div#contact div.contact-right .row {
    height: auto; }
    @media (max-width: 991.98px) {
      div#contact div.contact-right .row {
        height: auto; } }
    div#contact div.contact-right .row > div {
      border-right: solid 1px #000; }
      div#contact div.contact-right .row > div:last-child {
        border-right: transparent; }
        @media (min-width: 768px) and (max-width: 991.98px) {
          div#contact div.contact-right .row > div:last-child {
            border-right: solid 1px #000; } }
  div#contact div.contact-right:hover .contact-title {
    background: #000; }
    div#contact div.contact-right:hover .contact-title h3 {
      color: #fff; }
  @media (max-width: 991.98px) {
    div#contact .contact-title {
      border-left: solid 1px #000; } }
  @media (max-width: 767.98px) {
    div#contact .contact-title {
      background-color: #000; }
      div#contact .contact-title h3 {
        color: #fff; } }
  @media (max-width: 767.98px) {
    div#contact div.contact-left {
      padding: 0;
      border-bottom: solid 1px #000; } }

form.contact-form {
  padding-top: 25px;
  position: relative;
  background: #fff; }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    form.contact-form {
      padding-top: 24px; } }
  form.contact-form span {
    color: #000 !important; }
  form.contact-form.active-sending span.sending {
    display: block; }
  form.contact-form span.sending {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: -15px;
    right: -15px;
    padding: 15px;
    text-align: center;
    padding-top: 120px;
    background: rgba(255, 255, 255, 0.95);
    display: none;
    z-index: 1; }
    @media (max-width: 767.98px) {
      form.contact-form span.sending {
        padding-top: 22vh; } }
    @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
      form.contact-form span.sending {
        padding-top: 9vh !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.contact-form span.sending {
        padding-top: 12vh; } }
    @media (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
      form.contact-form span.sending {
        padding-top: 12vh; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      form.contact-form span.sending {
        padding-top: 15vh; } }
  form.contact-form.active-sent span.sent {
    display: block; }
  form.contact-form span.sent {
    position: absolute;
    width: auto;
    height: 100%;
    top: 0;
    left: -15px;
    right: -15px;
    padding: 15px;
    text-align: center;
    padding-top: 120px;
    background: rgba(255, 255, 255, 0.95);
    display: none;
    z-index: 1; }
    @media (max-width: 767.98px) {
      form.contact-form span.sent {
        padding-top: 22vh; } }
    @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
      form.contact-form span.sent {
        padding-top: 9vh !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.contact-form span.sent {
        padding-top: 12vh; } }
    @media (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
      form.contact-form span.sent {
        padding-top: 12vh; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      form.contact-form span.sent {
        padding-top: 15vh; } }
  form.contact-form label {
    display: none; }
  form.contact-form textarea, form.contact-form input {
    display: block;
    border: solid 1px #000 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none; }
    form.contact-form textarea:active, form.contact-form textarea:focus, form.contact-form input:active, form.contact-form input:focus {
      outline: solid 4px #000; }
  form.contact-form input {
    display: inline-block;
    width: calc(50% - 7px);
    vertical-align: top; }
    form.contact-form input + input {
      margin-left: 14px; }
    @media (max-width: 575.98px) {
      form.contact-form input {
        display: block;
        width: 100%; }
        form.contact-form input + input {
          margin: 15px 0 0 0; } }
  form.contact-form textarea {
    height: 115px; }
  form.contact-form button {
    color: #000;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border: solid 1px #000 !important;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    margin-bottom: calc(30px / 2); }
    form.contact-form button:hover, form.contact-form button:active, form.contact-form button:focus {
      background: #000;
      color: #fff; }
    @media (max-width: 991.98px) {
      form.contact-form button {
        margin-bottom: 20px; } }

/*!
 * [Preloader screen - Table of contents]
 * 1. Wrapper | .preloader
 */
body.of-hidden {
  overflow: hidden; }

div.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: #fff;
  text-align: center; }
  div.preloader div {
    height: 80px;
    position: relative;
    top: 50%;
    margin-top: -40px; }
  div.preloader p {
    font-size: 1.999em;
    text-transform: uppercase;
    position: relative;
    left: -2px; }
    div.preloader p span {
      color: #fff; }
      div.preloader p span.load-letter {
        color: #000; }
    div.preloader p + p {
      font-size: 1em;
      color: #000;
      text-transform: none;
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
      top: -5px; }
      div.preloader p + p.is-loaded {
        filter: alpha(opacity=100);
        -moz-opacity: 1;
        -khtml-opacity: 1;
        opacity: 1; }
  div.preloader.preloader-lg p.loading-text {
    font-size: 120px;
    line-height: 120px;
    margin-bottom: 15px; }
    @media (max-width: 767px) {
      div.preloader.preloader-lg p.loading-text {
        font-size: 1.999em !important;
        line-height: 30px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div.preloader.preloader-lg p.loading-text {
        font-size: 90px !important;
        line-height: 90px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      div.preloader.preloader-lg p.loading-text {
        font-size: 180px;
        line-height: 180px; } }
  div.preloader.preloader-lg div {
    height: 180px;
    margin-top: -120px; }
    @media (max-width: 767px) {
      div.preloader.preloader-lg div {
        height: 75px !important;
        margin-top: -40px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div.preloader.preloader-lg div {
        height: 160px !important;
        margin-top: -110px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      div.preloader.preloader-lg div {
        height: 220px !important;
        margin-top: -140px !important; } }
  div.preloader.preloader-xl p.loading-text {
    font-size: 240px;
    line-height: 240px;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      div.preloader.preloader-xl p.loading-text {
        font-size: 1.999em !important;
        line-height: 60px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div.preloader.preloader-xl p.loading-text {
        font-size: 120px !important;
        line-height: 120px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      div.preloader.preloader-xl p.loading-text {
        font-size: 130px !important;
        line-height: 130px !important; } }
  div.preloader.preloader-xl div {
    height: 240px;
    margin-top: -180px; }
    @media (max-width: 767px) {
      div.preloader.preloader-xl div {
        height: 90px !important;
        margin-top: -50px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div.preloader.preloader-xl div {
        height: 160px !important;
        margin-top: -110px !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      div.preloader.preloader-xl div {
        height: 160px !important;
        margin-top: -90px !important; } }
  div.preloader.preloader-off {
    display: none !important; }

div.modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border: solid 4px #000; }

div.modal-header {
  border-bottom: solid 1px #000;
  background-color: #fff; }
  div.modal-header button.close {
    filter: alpha(opacity=100) !important;
    -moz-opacity: 1 !important;
    -khtml-opacity: 1 !important;
    opacity: 1 !important; }
    div.modal-header button.close span {
      color: #000; }

div.modal-body {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #fff; }
  div.modal-body h6 {
    color: #000; }
  div.modal-body p {
    color: #000; }

div.modal-footer {
  padding: 20px 15px;
  border-top: solid 1px #000;
  background: #fff; }
  div.modal-footer:hover {
    background: #fff; }
    div.modal-footer:hover button.btn.btn-primary {
      background: #000;
      color: #fff; }

div.contact-modal div.modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border: solid 4px #000; }

div.contact-modal div.modal-header {
  border-bottom: solid 1px #000;
  background-color: #fff; }

div.contact-modal div.modal-body {
  padding-top: 0;
  padding-bottom: 30px; }

div.contact-modal div.modal-footer {
  padding: 30px 15px 25px;
  border-top: solid 1px #000;
  background: #fff; }
  div.contact-modal div.modal-footer:hover {
    background: #fff; }
    div.contact-modal div.modal-footer:hover button.btn.btn-primary {
      background: #000;
      color: #fff; }

div.contact-modal label {
  margin-top: 30px;
  margin-bottom: 0;
  background: #000;
  color: #fff;
  padding: 1px 10px;
  display: inline-block;
  font-size: 14px;
  text-align: center; }

div.contact-modal form.contact-form {
  padding-top: 0;
  overflow: hidden; }
  div.contact-modal form.contact-form span {
    padding-top: 210px; }
    @media (min-width: 768px) and (max-width: 1024px) {
      div.contact-modal form.contact-form span {
        padding-top: 200px !important; } }

div.contact-modal div.form-group {
  text-align: right;
  margin: 0; }

div.contact-modal button.btn.btn-primary, div.contact-modal input[type="text"], div.contact-modal input[type="email"] {
  width: 100%;
  color: #000;
  text-align: left;
  height: 45px; }
  div.contact-modal button.btn.btn-primary:focus, div.contact-modal button.btn.btn-primary:active, div.contact-modal input[type="text"]:focus, div.contact-modal input[type="text"]:active, div.contact-modal input[type="email"]:focus, div.contact-modal input[type="email"]:active {
    -webkit-box-shadow: 0px 2px 0px 0px #000;
    -moz-box-shadow: 0px 2px 0px 0px #000;
    -o-box-shadow: 0px 2px 0px 0px #000;
    box-shadow: 0px 2px 0px 0px #000; }

div.contact-modal textarea {
  height: 120px;
  margin-bottom: 15px !important; }
  div.contact-modal textarea:focus, div.contact-modal textarea:active {
    -webkit-box-shadow: 0px 2px 0px 0px #000;
    -moz-box-shadow: 0px 2px 0px 0px #000;
    -o-box-shadow: 0px 2px 0px 0px #000;
    box-shadow: 0px 2px 0px 0px #000; }

div.contact-modal input[type="text"], div.contact-modal input[type="email"], div.contact-modal textarea {
  border: solid 1px #000 !important;
  margin-bottom: 0;
  outline: none !important; }

div.contact-modal button.btn.btn-primary {
  background: #fff;
  color: #000;
  text-align: center;
  border: solid 1px #000 !important;
  border-color: #000;
  margin-bottom: 0; }
  div.contact-modal button.btn.btn-primary:hover {
    background: #000;
    color: #fff; }

form.sub-form {
  position: relative;
  padding: 0 30px;
  overflow: hidden;
  border-left: solid 1px #000; }
  form.sub-form.active-sending span.sending {
    display: block; }
  form.sub-form span {
    color: #000; }
  form.sub-form span.sending {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 15px;
    text-align: center;
    padding-top: 160px;
    background: rgba(255, 255, 255, 0.95);
    border: solid 1px #000;
    border-left: transparent;
    display: none;
    z-index: 3; }
    @media (max-width: 767.98px) {
      form.sub-form span.sending {
        padding-top: 110px;
        border: none; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.sub-form span.sending {
        padding-top: 120px; } }
  form.sub-form.active-sent span.sent {
    display: block; }
  form.sub-form span.sent {
    content: 'You\'ve are subscribed!';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 15px;
    text-align: center;
    padding-top: 160px;
    background: rgba(255, 255, 255, 0.95);
    border: solid 1px #000;
    border-left: transparent;
    display: none;
    z-index: 3; }
    @media (max-width: 767.98px) {
      form.sub-form span.sent {
        padding-top: 110px;
        border: none; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.sub-form span.sent {
        padding-top: 120px; } }
  @media (max-width: 767.98px) {
    form.sub-form {
      border-left: transparent;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0 15px; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    form.sub-form {
      padding-top: 30px;
      border: solid 1px #000; } }
  form.sub-form h4 {
    margin-bottom: 30px;
    padding-top: 22px; }
    @media (max-width: 767.98px) {
      form.sub-form h4 {
        margin-bottom: 20px;
        font-size: 1.414em;
        display: block;
        text-align: left !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.sub-form h4 {
        padding-top: 5px; } }
  form.sub-form label {
    display: none; }
  form.sub-form button.btn.btn-primary, form.sub-form input[type="text"], form.sub-form input[type="email"] {
    width: 100%;
    color: #000;
    text-align: left;
    height: 45px;
    margin-bottom: 19px;
    border-bottom: solid 1px #000 !important; }
    form.sub-form button.btn.btn-primary:focus, form.sub-form button.btn.btn-primary:active, form.sub-form input[type="text"]:focus, form.sub-form input[type="text"]:active, form.sub-form input[type="email"]:focus, form.sub-form input[type="email"]:active {
      -webkit-box-shadow: 0px 2px 0px 0px #fff;
      -moz-box-shadow: 0px 2px 0px 0px #fff;
      -o-box-shadow: 0px 2px 0px 0px #fff;
      box-shadow: 0px 2px 0px 0px #fff; }
    @media (max-width: 767.98px) {
      form.sub-form button.btn.btn-primary, form.sub-form input[type="text"], form.sub-form input[type="email"] {
        margin-bottom: 15px;
        height: 40px; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.sub-form button.btn.btn-primary, form.sub-form input[type="text"], form.sub-form input[type="email"] {
        margin-bottom: 15px; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    form.sub-form input[type="text"], form.sub-form input[type="email"] {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      border: solid 1px #000 !important; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    form.sub-form input[type="text"] {
      position: relative;
      z-index: 1; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    form.sub-form input[type="email"] {
      margin-left: -6px; } }
  form.sub-form button.btn.btn-primary {
    text-align: center;
    margin: 30px 0;
    height: 60px; }
    form.sub-form button.btn.btn-primary:hover {
      -webkit-box-shadow: 0px 2px 0px 0px #fff;
      -moz-box-shadow: 0px 2px 0px 0px #fff;
      -o-box-shadow: 0px 2px 0px 0px #fff;
      box-shadow: 0px 2px 0px 0px #fff; }
    @media (max-width: 767.98px) {
      form.sub-form button.btn.btn-primary {
        height: 45px;
        margin-bottom: 20px;
        margin-top: 10px; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      form.sub-form button.btn.btn-primary {
        margin: 15px 0 45px;
        border: solid 1px #000 !important; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    form.sub-form div.form-group {
      margin-bottom: 0 !important; } }

/*!
 * [Footer - Table of contents]
 * 1. Footer
 */
footer .row > div {
  border-top: transparent;
  border-bottom: transparent; }

footer p {
  padding-top: 30px;
  position: relative;
  right: -12px;
  font-size: 0.875em; }

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(255, 255, 255, 0.01) 0 0 1px; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: "liga" on;
  background-color: #fff;
  border-bottom: solid 1px #000; }
  @media (min-width: 768px) and (max-width: 1024px) {
    body {
      border: none;
      border-bottom: solid 1px #000; } }
  @media (max-width: 575.98px) {
    body {
      border: solid 4px #000; } }
  body.body-fs {
    padding-top: 47px; }
    @media (max-width: 767.98px) {
      body.body-fs {
        padding-top: 42px; } }

h1, h2, h3, h4, h5, p, li, label, input, textarea, strong, button {
  color: #000; }

input, textarea {
  color: #000 !important;
  background-color: #fff !important; }

a {
  color: #000; }
  a:hover {
    color: #000;
    text-decoration: none; }

.anchor {
  cursor: pointer; }
  .anchor:hover {
    background: #000;
    color: #fff; }
    .anchor:hover > * {
      color: #fff; }

.elem-border > div {
  border: solid 1px #000; }
  @media (max-width: 575.98px) {
    .elem-border > div {
      border-left: transparent;
      border-right: transparent; } }
  .elem-border > div + div {
    border-left: transparent; }

.btn-style-main {
  position: relative;
  -webkit-transition-delay: .30s;
  -moz-transition-delay: .30s;
  -o-transition-delay: .30s;
  transition-delay: .30s; }
  .btn-style-main, .btn-style-main a, .btn-style-main span, .btn-style-main button {
    outline: solid 1px #fff;
    display: block;
    padding: 15px;
    text-align: center;
    width: 100%;
    height: auto;
    -webkit-transition: outline .15s ease-in-out;
    -moz-transition: outline .15s ease-in-out;
    -o-transition: outline .15s ease-in-out;
    transition: outline .15s ease-in-out; }
  .btn-style-main a, .btn-style-main button {
    -webkit-transition-delay: .15s;
    -moz-transition-delay: .15s;
    -o-transition-delay: .15s;
    transition-delay: .15s; }
    .btn-style-main a:hover span, .btn-style-main button:hover span {
      background: #000;
      color: #fff; }
  .btn-style-main span {
    outline: solid 1px #000; }
  .btn-style-main:hover {
    outline: solid 1px #000; }
    .btn-style-main:hover a, .btn-style-main:hover button {
      outline: solid 1px #000; }

a.opl {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3; }

.stretch-text span {
  display: inline-block !important;
  white-space: nowrap; }
  @media (max-width: 767px) {
    .stretch-text span {
      white-space: normal; } }

/*!
 * [Fullscreen section - Table of contents]
 * 1. Wrapper | #fullscreen
 * 2. Call to action | .cta
 */
html.html-fs, body.body-fs {
  height: 100%; }
  @media (max-width: 767.98px) {
    html.html-fs, body.body-fs {
      height: auto; } }

/*!
 * [Secondary Navigation - Table of contents]
 * 1. Nav | .nav-fs
 * 2. Nav items | ul.navlist
 * 3. Contact | a.contact
 * 4. Branding | .brand
 */
nav.nav-fs {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  background: #000; }
  nav.nav-fs div.navigation {
    padding: 0; }
    @media (max-width: 767.98px) {
      nav.nav-fs div.navigation {
        display: none; } }
  nav.nav-fs ul.navlist {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
    nav.nav-fs ul.navlist li {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: calc(100% / 4);
      border-bottom: transparent;
      border-right: solid 1px #000;
      text-transform: uppercase;
      display: inline-block;
      text-align: center; }
      nav.nav-fs ul.navlist li:last-child {
        border-right: transparent; }
      @media (max-width: 767.98px) {
        nav.nav-fs ul.navlist li {
          font-size: 0.875em;
          padding: 0; } }
      nav.nav-fs ul.navlist li:hover {
        background: #fff; }
        nav.nav-fs ul.navlist li:hover a {
          color: #000; }
      nav.nav-fs ul.navlist li a {
        color: #fff; }
  nav.nav-fs a.contact {
    display: inline-block;
    width: auto;
    height: auto;
    text-transform: uppercase;
    text-align: center !important;
    line-height: 45px;
    height: 45px; }
  nav.nav-fs div.anchor.brand {
    position: relative; }
    nav.nav-fs div.anchor.brand:hover {
      background-color: #fff; }
      nav.nav-fs div.anchor.brand:hover a {
        color: #000 !important; }
      nav.nav-fs div.anchor.brand:hover button#mobile-nav {
        color: #000 !important; }
    nav.nav-fs div.anchor.brand a {
      line-height: 45px;
      height: 45px;
      display: inline-block;
      font-size: 1.65em;
      color: #fff; }
      @media (max-width: 767.98px) {
        nav.nav-fs div.anchor.brand a {
          font-size: 1.35em; } }
    nav.nav-fs div.anchor.brand button#mobile-nav {
      color: #fff;
      position: absolute;
      right: 15px;
      top: 10px;
      padding: 0;
      display: none; }
      nav.nav-fs div.anchor.brand button#mobile-nav:focus, nav.nav-fs div.anchor.brand button#mobile-nav:active {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none; }
      @media (max-width: 767.98px) {
        nav.nav-fs div.anchor.brand button#mobile-nav {
          display: block; } }

section#fullscreen {
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  text-align: center;
  position: relative;
  display: table;
  border-left: solid 1px #000;
  border-right: solid 1px #000;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  @media (max-width: 767.98px) {
    section#fullscreen {
      text-align: center;
      padding: 30px 0; } }
  section#fullscreen > div {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    section#fullscreen > div > div {
      width: 800px;
      text-align: left;
      position: relative;
      display: inline-block; }
      @media (max-width: 767.98px) {
        section#fullscreen > div > div {
          width: 90%;
          text-align: center; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        section#fullscreen > div > div {
          width: 90%; } }
      section#fullscreen > div > div:hover h1, section#fullscreen > div > div:hover h2, section#fullscreen > div > div:hover .cta {
        border-color: #fff !important; }
  section#fullscreen h1, section#fullscreen h2 {
    color: #fff;
    background-color: #000;
    padding: 0 15px;
    display: inline-block;
    width: auto;
    border: solid 1px #000; }
    @media (max-width: 767.98px) {
      section#fullscreen h1, section#fullscreen h2 {
        display: inline-block;
        width: 100%; } }
  section#fullscreen h1 {
    font-size: 163px;
    margin-bottom: 0;
    position: relative;
    line-height: 150px;
    padding: 3px 29px 6px 17px; }
    @media (max-width: 767.98px) {
      section#fullscreen h1 {
        font-size: 70px;
        margin-bottom: 15px;
        padding: 0; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen h1 {
        padding: 3px 29px 13px 17px; } }
  section#fullscreen h2 {
    font-size: 2.827em;
    padding: 20px 27px 20px 27px;
    margin-top: -1px; }
    @media (max-width: 767.98px) {
      section#fullscreen h2 {
        font-size: 1.999em;
        padding: 15px;
        margin-bottom: 15px; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen h2 {
        font-size: 2.5em; } }
  section#fullscreen .cta {
    position: relative;
    border: solid 1px #000;
    display: inline-block !important;
    width: 50% !important;
    left: 50%;
    margin-left: -24px;
    margin-top: -1px;
    background: #000;
    text-transform: uppercase;
    font-size: 19px;
    -webkit-transition-delay: 0.3s;
    -moz-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s; }
    section#fullscreen .cta br {
      display: none; }
    section#fullscreen .cta, section#fullscreen .cta a, section#fullscreen .cta span {
      outline: solid 1px transparent;
      display: block;
      padding: 15px;
      text-align: center;
      width: 100%;
      height: auto;
      -webkit-transition: outline 0.15s ease-in-out;
      -moz-transition: outline 0.15s ease-in-out;
      -o-transition: outline 0.15s ease-in-out;
      transition: outline 0.15s ease-in-out; }
    section#fullscreen .cta a {
      -webkit-transition-delay: 0.15s;
      -moz-transition-delay: 0.15s;
      -o-transition-delay: 0.15s;
      transition-delay: 0.15s; }
      section#fullscreen .cta a:hover span {
        background: #fff;
        color: #000; }
    section#fullscreen .cta span {
      outline: solid 1px #fff;
      color: #fff; }
    section#fullscreen .cta:hover a {
      outline: solid 1px #fff; }
    @media (max-width: 767.98px) {
      section#fullscreen .cta {
        left: initial;
        top: 0;
        width: 100% !important;
        display: inline-block !important;
        position: relative;
        margin: 0;
        font-size: 16px; }
        section#fullscreen .cta span {
          padding: 10px; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen .cta {
        left: 54%;
        margin-left: -28px; } }

/*!
 * [Coming soon page - Table of contents]
 * 1. Wrapper | #fullscreen.fs-coming-soon
 * 2. Fullscreen nav bar
 */
html.html-fs, body.body-fs.coming-soon {
  height: 100%;
  padding-top: 0; }
  @media (max-width: 767.98px) {
    html.html-fs, body.body-fs.coming-soon {
      height: auto; }
      html.html-fs.coming-soon-countdown .opl, body.body-fs.coming-soon.coming-soon-countdown .opl {
        display: none; } }

/*!
 * [Secondary Navigation - Table of contents]
 * 1. Nav | .nav-fs
 * 2. Nav items | ul.navlist
 * 3. Contact | a.contact
 * 4. Branding | .brand
 */
nav.nav-fs {
  position: fixed;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 99;
  background: #000; }
  nav.nav-fs div.navigation {
    padding: 0; }
    @media (max-width: 767.98px) {
      nav.nav-fs div.navigation {
        display: none; } }
  nav.nav-fs ul.navlist {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
    nav.nav-fs ul.navlist li {
      height: 45px;
      line-height: 45px;
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: calc(100% / 4);
      border-bottom: transparent;
      border-right: solid 1px #000;
      text-transform: uppercase;
      display: inline-block;
      text-align: center; }
      nav.nav-fs ul.navlist li:last-child {
        border-right: transparent; }
      @media (max-width: 767.98px) {
        nav.nav-fs ul.navlist li {
          font-size: 0.875em;
          padding: 0; } }
      nav.nav-fs ul.navlist li:hover {
        background: #fff; }
        nav.nav-fs ul.navlist li:hover a {
          color: #000; }
      nav.nav-fs ul.navlist li a {
        color: #fff; }
  nav.nav-fs a.contact {
    display: inline-block;
    width: auto;
    height: auto;
    text-transform: uppercase;
    text-align: center !important;
    line-height: 45px;
    height: 45px; }
  nav.nav-fs div.anchor.brand {
    position: relative; }
    nav.nav-fs div.anchor.brand:hover {
      background-color: #fff; }
      nav.nav-fs div.anchor.brand:hover a {
        color: #000 !important; }
      nav.nav-fs div.anchor.brand:hover button#mobile-nav {
        color: #000 !important; }
    nav.nav-fs div.anchor.brand a {
      line-height: 45px;
      height: 45px;
      display: inline-block;
      font-size: 1.65em;
      color: #fff; }
      @media (max-width: 767.98px) {
        nav.nav-fs div.anchor.brand a {
          font-size: 1.35em; } }
    nav.nav-fs div.anchor.brand button#mobile-nav {
      color: #fff;
      position: absolute;
      right: 15px;
      top: 10px;
      padding: 0;
      display: none; }
      nav.nav-fs div.anchor.brand button#mobile-nav:focus, nav.nav-fs div.anchor.brand button#mobile-nav:active {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none; }
      @media (max-width: 767.98px) {
        nav.nav-fs div.anchor.brand button#mobile-nav {
          display: block; } }

section#fullscreen.fs-coming-soon {
  padding: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  text-align: center;
  position: relative;
  display: table;
  border-left: solid 1px #000;
  border-right: solid 1px #000;
  border-top: solid 1px #000; }
  @media (max-width: 767.98px) {
    section#fullscreen.fs-coming-soon {
      text-align: center;
      padding: 15px 0 30px; } }
  section#fullscreen.fs-coming-soon > div {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    section#fullscreen.fs-coming-soon > div > div.container {
      width: auto;
      text-align: left;
      position: relative;
      display: inline-block; }
      @media (min-width: 414px) and (max-width: 736px) and (orientation: portrait) {
        section#fullscreen.fs-coming-soon > div > div.container {
          padding: 0 !important;
          width: calc(100% - 30px) !important; } }
      @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
        section#fullscreen.fs-coming-soon > div > div.container div.row > div {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        section#fullscreen.fs-coming-soon > div > div.container h1 {
          overflow: hidden;
          margin-bottom: -6px; } }
      section#fullscreen.fs-coming-soon > div > div.container:hover h1, section#fullscreen.fs-coming-soon > div > div.container:hover h2, section#fullscreen.fs-coming-soon > div > div.container:hover h3, section#fullscreen.fs-coming-soon > div > div.container:hover .cta {
        border-color: #000 !important;
        background: #fff;
        color: #000; }
      section#fullscreen.fs-coming-soon > div > div.container:hover form.sub-form {
        background: #000; }
        section#fullscreen.fs-coming-soon > div > div.container:hover form.sub-form h4 {
          color: #fff; }
        section#fullscreen.fs-coming-soon > div > div.container:hover form.sub-form button.btn.btn-primary {
          background: #fff; }
      @media (max-width: 767.98px) {
        section#fullscreen.fs-coming-soon > div > div.container {
          text-align: center; }
          section#fullscreen.fs-coming-soon > div > div.container h1, section#fullscreen.fs-coming-soon > div > div.container h2, section#fullscreen.fs-coming-soon > div > div.container h3, section#fullscreen.fs-coming-soon > div > div.container .cta {
            border-color: #000 !important;
            background: #fff;
            color: #000; }
          section#fullscreen.fs-coming-soon > div > div.container form.sub-form {
            background: #000; }
            section#fullscreen.fs-coming-soon > div > div.container form.sub-form h4 {
              color: #fff; }
            section#fullscreen.fs-coming-soon > div > div.container form.sub-form button.btn.btn-primary {
              background: #fff; } }
  section#fullscreen.fs-coming-soon h1, section#fullscreen.fs-coming-soon h2, section#fullscreen.fs-coming-soon h3 {
    color: #fff;
    background-color: #000;
    padding: 0 15px;
    display: inline-block;
    width: auto;
    border: solid 1px #000; }
    @media (max-width: 767.98px) {
      section#fullscreen.fs-coming-soon h1, section#fullscreen.fs-coming-soon h2, section#fullscreen.fs-coming-soon h3 {
        display: inline-block;
        width: 100%; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.fs-coming-soon h1, section#fullscreen.fs-coming-soon h2, section#fullscreen.fs-coming-soon h3 {
        border-color: #000 !important; } }
  section#fullscreen.fs-coming-soon h1 {
    font-size: 140px;
    margin-bottom: 0;
    position: relative;
    line-height: 150px;
    padding: 3px 10px 6px 17px;
    width: 100%;
    text-align: left;
    letter-spacing: 2px;
    padding-left: 16px;
    font-weight: normal; }
    @media (max-width: 767.98px) {
      section#fullscreen.fs-coming-soon h1 {
        font-size: 70px;
        margin-bottom: 0;
        line-height: 70px;
        padding: 20px 10px;
        text-align: center; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.fs-coming-soon h1 {
        padding: 3px 0 13px 17px;
        border-top: solid 1px #000;
        border-bottom: solid 1px #000;
        border-top-color: #fff !important;
        border-bottom-color: #fff !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      section#fullscreen.fs-coming-soon h1 {
        font-size: 122px;
        padding-left: 11px; } }
  section#fullscreen.fs-coming-soon h2 {
    font-size: 2.2em;
    padding: 20px 27px 20px 27px;
    margin-top: -1px;
    text-align: center;
    width: 100%;
    height: 90px;
    line-height: 50px; }
    @media (max-width: 767.98px) {
      section#fullscreen.fs-coming-soon h2 {
        font-size: 1.999em;
        padding: 10px;
        margin-bottom: 0;
        line-height: 36px;
        height: auto;
        text-align: left;
        font-weight: 300;
        text-align: center; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.fs-coming-soon h2 {
        font-size: 2.5em;
        font-weight: 300; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      section#fullscreen.fs-coming-soon h2 {
        font-weight: 300;
        font-size: 30px;
        padding: 20px 15px; } }
  section#fullscreen.fs-coming-soon h3 {
    font-size: 50px;
    padding: 20px 0;
    margin-bottom: -1px;
    width: 100%;
    letter-spacing: 17px;
    text-align: center;
    position: relative;
    padding-left: 13px;
    height: 90px;
    line-height: 50px; }
    @media (max-width: 767.98px) {
      section#fullscreen.fs-coming-soon h3 {
        font-size: 1.999em;
        padding: 15px 10px;
        letter-spacing: 5px;
        height: auto;
        font-weight: 300;
        text-align: center; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.fs-coming-soon h3 {
        font-size: 2.5em;
        font-weight: 300; }
        section#fullscreen.fs-coming-soon h3 span {
          min-width: 100%; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      section#fullscreen.fs-coming-soon h3 {
        font-size: 42px;
        padding-right: 0;
        letter-spacing: 15px;
        font-weight: 300; } }
  section#fullscreen.fs-coming-soon p.coming-soon-contact, section#fullscreen.fs-coming-soon p.coming-soon-copyright {
    position: absolute;
    bottom: 15px;
    width: 100%;
    z-index: 3; }
  section#fullscreen.fs-coming-soon p.coming-soon-contact {
    bottom: 135px;
    left: 0;
    right: 0; }
    section#fullscreen.fs-coming-soon p.coming-soon-contact a {
      display: inline-block;
      position: relative; }
      section#fullscreen.fs-coming-soon p.coming-soon-contact a:hover:before {
        height: 3px; }
      section#fullscreen.fs-coming-soon p.coming-soon-contact a:before {
        content: '';
        position: absolute;
        top: calc(100% - 2px);
        left: 0;
        width: 100%;
        height: 1px;
        background: #000;
        -webkit-transition: height .3s ease-in-out;
        transition: height .3s ease-in-out; }
    @media (max-width: 767.98px) {
      section#fullscreen.fs-coming-soon p.coming-soon-contact {
        bottom: 15px;
        padding-left: 15px;
        text-align: left;
        font-size: 0.875em;
        z-index: 4;
        left: 25px;
        width: auto; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.fs-coming-soon p.coming-soon-contact {
        bottom: 15px;
        padding-left: 35px;
        text-align: left;
        font-size: 0.875em;
        z-index: 4;
        left: 50px;
        width: auto; } }
  section#fullscreen.fs-coming-soon p.coming-soon-copyright {
    text-align: right;
    padding-right: 35px;
    font-size: 0.875em;
    left: 0; }
    @media (max-width: 767.98px) {
      section#fullscreen.fs-coming-soon p.coming-soon-copyright {
        padding-right: 15px; } }

/*!
 * [Coming soon countdown page - Table of contents]
 * 1. Timer wrap | .timer-wrap-outer
 * 2. Fullscreen | #fullscreen.coming-soon-countdown
 */
.timer-wrap-outer {
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .timer-wrap-outer {
      position: relative;
      bottom: 0;
      margin: 15px 0 15px; } }
  .timer-wrap-outer .timer-wrap {
    width: 100%; }
    .timer-wrap-outer .timer-wrap > span {
      padding-bottom: 8px; }
    .timer-wrap-outer .timer-wrap span {
      display: inline-block;
      width: calc(91% / 4);
      text-align: center;
      vertical-align: middle;
      font-weight: 300;
      color: #000;
      border: solid 1px #000;
      border-color: #fff; }
      .timer-wrap-outer .timer-wrap span:hover {
        border-color: #000; }
      @media (max-width: 767.98px) {
        .timer-wrap-outer .timer-wrap span {
          font-size: 0.875em; } }
      .timer-wrap-outer .timer-wrap span span.time {
        color: #000;
        background: #fff;
        width: 100%;
        padding: 0;
        font-size: 45px;
        font-weight: 300;
        margin-bottom: 0px;
        border: none !important;
        line-height: 60px; }
        @media (max-width: 767px) {
          .timer-wrap-outer .timer-wrap span span.time {
            font-size: 27px; } }
  .timer-wrap-outer span.sep {
    background-color: #fff;
    color: #000;
    width: 3% !important;
    vertical-align: top !important;
    line-height: 77px;
    font-size: 33px !important;
    position: relative;
    top: 5px;
    font-weight: 300;
    border: none !important; }
    @media (max-width: 767.98px) {
      .timer-wrap-outer span.sep {
        top: -9px; } }

section#fullscreen.coming-soon-countdown > div > div.container:hover .timer-wrap-outer .timer-wrap span {
  border-color: #000; }

section#fullscreen.coming-soon-countdown p.coming-soon-contact {
  bottom: 90px; }
  @media (max-width: 767.98px) {
    section#fullscreen.coming-soon-countdown p.coming-soon-contact {
      bottom: 15px; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    section#fullscreen.coming-soon-countdown p.coming-soon-contact {
      bottom: 15px; } }

/*!
 * [Coming soon background page - Table of contents]
 * 1. Wrapper | #fullscreen.coming-soon-background
 * 2. Fullscreen nav bar
 */
section#fullscreen.coming-soon-background {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  table-layout: fixed; }
  @media (max-width: 767px) {
    section#fullscreen.coming-soon-background {
      padding-top: 30px; } }
  section#fullscreen.coming-soon-background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    background-color: #fff;
    z-index: 1; }
    @media (max-width: 767.98px) {
      section#fullscreen.coming-soon-background:after {
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        top: initial; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.coming-soon-background:after {
        width: 100%;
        height: 50%;
        bottom: 0;
        left: 0;
        top: initial; } }
  section#fullscreen.coming-soon-background:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1; }
    @media (max-width: 767.98px) {
      section#fullscreen.coming-soon-background:before {
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5); } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.coming-soon-background:before {
        width: 100%;
        height: 50%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5); } }
  section#fullscreen.coming-soon-background > div {
    position: relative;
    z-index: 2;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100%; }
    section#fullscreen.coming-soon-background > div > div.container {
      width: auto;
      text-align: left;
      position: relative;
      display: inline-block; }
      section#fullscreen.coming-soon-background > div > div.container h1, section#fullscreen.coming-soon-background > div > div.container h2, section#fullscreen.coming-soon-background > div > div.container h3, section#fullscreen.coming-soon-background > div > div.container .cta {
        border-color: #fff !important;
        background: transparent !important; }
        @media (max-width: 767.98px) {
          section#fullscreen.coming-soon-background > div > div.container h1, section#fullscreen.coming-soon-background > div > div.container h2, section#fullscreen.coming-soon-background > div > div.container h3, section#fullscreen.coming-soon-background > div > div.container .cta {
            color: #fff !important; } }
      section#fullscreen.coming-soon-background > div > div.container h2, section#fullscreen.coming-soon-background > div > div.container h3 {
        font-weight: 300; }
      section#fullscreen.coming-soon-background > div > div.container:hover h1, section#fullscreen.coming-soon-background > div > div.container:hover h2, section#fullscreen.coming-soon-background > div > div.container:hover h3, section#fullscreen.coming-soon-background > div > div.container:hover .cta {
        border-color: #fff !important;
        color: #fff !important; }
      section#fullscreen.coming-soon-background > div > div.container:hover form.sub-form {
        background: #000; }
        section#fullscreen.coming-soon-background > div > div.container:hover form.sub-form h4 {
          color: #fff; }
        section#fullscreen.coming-soon-background > div > div.container:hover form.sub-form button.btn.btn-primary {
          background: #fff; }
  section#fullscreen.coming-soon-background form.sub-form {
    border-left: transparent;
    border: solid 1px #000; }
  section#fullscreen.coming-soon-background p.coming-soon-contact {
    bottom: 15px;
    left: 85px;
    right: initial;
    width: auto;
    text-align: left; }
    section#fullscreen.coming-soon-background p.coming-soon-contact a {
      color: #fff; }
      section#fullscreen.coming-soon-background p.coming-soon-contact a:before {
        background-color: #fff; }
    @media (max-width: 767.98px) {
      section#fullscreen.coming-soon-background p.coming-soon-contact {
        padding-left: 0;
        left: 45px; }
        section#fullscreen.coming-soon-background p.coming-soon-contact a {
          color: #000; }
          section#fullscreen.coming-soon-background p.coming-soon-contact a:before {
            background-color: #000; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      section#fullscreen.coming-soon-background p.coming-soon-contact {
        left: 75px;
        padding-left: 15px; }
        section#fullscreen.coming-soon-background p.coming-soon-contact a {
          color: #000; }
          section#fullscreen.coming-soon-background p.coming-soon-contact a:before {
            background-color: #000; } }
  section#fullscreen.coming-soon-background p.coming-soon-copyright {
    width: auto;
    left: initial;
    right: 0;
    padding-right: 15px; }
  section#fullscreen.coming-soon-background a.unsplash {
    text-align: right;
    display: block;
    color: rgba(255, 255, 255, 0.25);
    font-size: 12px;
    position: absolute;
    left: 0;
    right: 15px;
    padding-top: 2px; }
    @media (max-width: 767px) {
      section#fullscreen.coming-soon-background a.unsplash {
        position: relative; } }
  @media (max-width: 767.98px) {
    section#fullscreen.coming-soon-background div.sub-form-wrap {
      margin-top: 30px;
      background-color: #fff; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    section#fullscreen.coming-soon-background div.titles-wrap {
      position: relative;
      top: -60px;
      overflow: hidden; } }

/*!
 * [Coming soon countdown with background page - Table of contents]
 * 1. Fullscreen | #fullscreen.coming-soon-countdown-background
 * 2. Countdown timer | .coming-soon-bg-timer
 */
@media (max-width: 767.98px) {
  section#fullscreen.coming-soon-background.coming-soon-countdown-background {
    padding-top: 0; }
    section#fullscreen.coming-soon-background.coming-soon-countdown-background:before {
      height: 60%; }
    section#fullscreen.coming-soon-background.coming-soon-countdown-background:after {
      height: 40%; } }

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  section#fullscreen.coming-soon-background.coming-soon-countdown-background div.titles-wrap {
    top: 0; }
  section#fullscreen.coming-soon-background.coming-soon-countdown-background:before {
    height: 60%; }
  section#fullscreen.coming-soon-background.coming-soon-countdown-background:after {
    height: 43.5%; }
  section#fullscreen.coming-soon-background.coming-soon-countdown-background form.sub-form {
    padding-top: 0 !important;
    margin-top: 60px !important; }
    section#fullscreen.coming-soon-background.coming-soon-countdown-background form.sub-form h4 {
      padding-top: 30px; }
  section#fullscreen.coming-soon-background.coming-soon-countdown-background > div > div.container:hover div.timer-wrap-outer {
    background: #fff;
    border-color: #000 !important;
    border-bottom: transparent; }
    section#fullscreen.coming-soon-background.coming-soon-countdown-background > div > div.container:hover div.timer-wrap-outer * {
      color: #000 !important;
      border-color: #000 !important; } }

div.timer-wrap-outer.coming-soon-bg-timer {
  background-color: #fff;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000; }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    div.timer-wrap-outer.coming-soon-bg-timer {
      margin-bottom: 0;
      margin-top: -90px;
      background-color: transparent;
      border-color: #fff !important;
      border-bottom: none !important; }
      div.timer-wrap-outer.coming-soon-bg-timer * {
        background: transparent !important;
        border-color: #fff !important; } }
  div.timer-wrap-outer.coming-soon-bg-timer .timer-wrap span {
    background-color: #fff;
    border: solid 1px #000;
    border-top: transparent;
    border-bottom: transparent; }
    @media (max-width: 767.98px) {
      div.timer-wrap-outer.coming-soon-bg-timer .timer-wrap span {
        border: none !important; }
        div.timer-wrap-outer.coming-soon-bg-timer .timer-wrap span.sep {
          background-color: transparent !important;
          border: none !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      div.timer-wrap-outer.coming-soon-bg-timer .timer-wrap span {
        border-color: #fff;
        color: #fff !important; } }

body.brutal-landing {
  border: solid 1px #000; }
  body.brutal-landing nav h1 {
    overflow: hidden;
    font-size: 140px; }
    @media (max-width: 767px) {
      body.brutal-landing nav h1 {
        line-height: 115px;
        font-size: 80px; } }
  body.brutal-landing .banner-container:hover {
    background-color: #000; }
    body.brutal-landing .banner-container:hover h1 {
      color: #fff; }
  body.brutal-landing div.tf-banner {
    background: #000; }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      body.brutal-landing div.tf-banner div#hero {
        padding: 60px 0; } }
    body.brutal-landing div.tf-banner div#hero > div {
      border-top: transparent;
      border-bottom: transparent;
      padding-top: 30px;
      padding-bottom: 30px; }
      @media (max-width: 767px) {
        body.brutal-landing div.tf-banner div#hero > div {
          padding-top: 45px;
          padding-bottom: 0; }
          body.brutal-landing div.tf-banner div#hero > div + div {
            padding-top: 0;
            margin: 0 0 60px; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        body.brutal-landing div.tf-banner div#hero > div {
          text-align: center !important;
          padding: 0; } }
      @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
        body.brutal-landing div.tf-banner div#hero > div {
          text-align: left !important; }
          body.brutal-landing div.tf-banner div#hero > div + div {
            text-align: right !important; } }
    body.brutal-landing div.tf-banner div#hero h2 {
      color: #fff;
      margin-bottom: 0;
      font-size: 50px;
      line-height: 60px;
      font-weight: 300; }
      @media (max-width: 767px) {
        body.brutal-landing div.tf-banner div#hero h2 {
          font-size: 1.999em;
          line-height: 42px;
          margin-bottom: 30px; } }
    body.brutal-landing div.tf-banner .btn-style-main {
      display: inline-block;
      width: 320px;
      margin: 0 auto; }
      body.brutal-landing div.tf-banner .btn-style-main, body.brutal-landing div.tf-banner .btn-style-main * {
        outline-color: #fff !important; }
      body.brutal-landing div.tf-banner .btn-style-main:hover *, body.brutal-landing div.tf-banner .btn-style-main:hover {
        outline-color: #fff !important; }
      body.brutal-landing div.tf-banner .btn-style-main:hover span {
        background-color: #fff !important;
        color: #000 !important; }
      @media (max-width: 767px) {
        body.brutal-landing div.tf-banner .btn-style-main {
          width: 100%; }
          body.brutal-landing div.tf-banner .btn-style-main, body.brutal-landing div.tf-banner .btn-style-main * {
            outline-color: #fff !important; }
          body.brutal-landing div.tf-banner .btn-style-main:hover span {
            background-color: #000 !important;
            color: #fff !important; } }
      @media (min-width: 768px) and (max-width: 1024px) {
        body.brutal-landing div.tf-banner .btn-style-main {
          margin-top: 45px; }
          body.brutal-landing div.tf-banner .btn-style-main, body.brutal-landing div.tf-banner .btn-style-main * {
            outline-color: #fff !important; }
          body.brutal-landing div.tf-banner .btn-style-main:hover span {
            background-color: #fff !important;
            color: #000 !important; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        body.brutal-landing div.tf-banner .btn-style-main {
          margin-top: 0;
          width: 100%; } }
      @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
        body.brutal-landing div.tf-banner .btn-style-main {
          margin-top: 0; } }
      body.brutal-landing div.tf-banner .btn-style-main span {
        outline: #fff solid 1px;
        display: block;
        padding: 15px;
        text-align: center;
        width: 100%;
        height: auto;
        -webkit-transition: outline .15s ease-in-out;
        transition: outline .15s ease-in-out;
        background-color: #000;
        text-transform: uppercase;
        color: #fff !important;
        font-size: 1.24em; }
        @media (max-width: 767px) {
          body.brutal-landing div.tf-banner .btn-style-main span {
            font-size: 16px;
            background-color: #000 !important;
            color: #fff !important; } }
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          body.brutal-landing div.tf-banner .btn-style-main span {
            background-color: #000;
            color: #fff !important; } }
  body.brutal-landing h3 {
    margin-bottom: 30px; }
  body.brutal-landing h4 {
    text-transform: none; }
  body.brutal-landing div.row.padded-row > div {
    padding-top: 60px;
    padding-bottom: 60px; }
    body.brutal-landing div.row.padded-row > div + div.row.padded-row > div {
      border-top: none; }
  body.brutal-landing div#examples img {
    width: 100%;
    height: auto;
    margin-bottom: 45px; }
    body.brutal-landing div#examples img > div {
      padding-bottom: 0; }
  body.brutal-landing ul {
    text-align: left;
    margin: 0 auto;
    width: 30%;
    list-style-type: square; }
    body.brutal-landing ul li {
      line-height: 32px;
      font-size: 19px;
      margin-bottom: 15px; }
      body.brutal-landing ul li:last-child {
        margin-bottom: 0; }
  body.brutal-landing div#about-brutal {
    padding: 0;
    border-bottom: solid 1px #000; }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      body.brutal-landing div#about-brutal {
        border-left: solid 1px #000;
        border-right: solid 1px #000; } }
    body.brutal-landing div#about-brutal div.row > div {
      padding-top: 60px;
      padding-bottom: 45px; }
      @media (max-width: 767px) {
        body.brutal-landing div#about-brutal div.row > div {
          padding-bottom: 60px;
          border-bottom: solid 1px #000; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        body.brutal-landing div#about-brutal div.row > div {
          padding: 60px 30px 45px 30px; } }
      body.brutal-landing div#about-brutal div.row > div div.about-inner {
        padding-right: 60px;
        padding-left: 0; }
        @media (max-width: 1024px) and (orientation: portrait) {
          body.brutal-landing div#about-brutal div.row > div div.about-inner {
            padding-right: 0; } }
      body.brutal-landing div#about-brutal div.row > div + div {
        border-left: solid 1px #000; }
        @media (max-width: 767px) {
          body.brutal-landing div#about-brutal div.row > div + div {
            border-left: none; } }
        body.brutal-landing div#about-brutal div.row > div + div div.about-inner {
          padding-right: 0;
          padding-left: 60px; }
          @media (max-width: 1024px) and (orientation: portrait) {
            body.brutal-landing div#about-brutal div.row > div + div div.about-inner {
              padding-left: 0; } }
    body.brutal-landing div#about-brutal h3 {
      color: #000;
      font-size: 1.999em;
      text-transform: none; }
    body.brutal-landing div#about-brutal ul {
      width: auto;
      margin-left: 24px; }
      body.brutal-landing div#about-brutal ul li {
        margin-bottom: 10px;
        line-height: 28px;
        font-size: 1em; }
  body.brutal-landing div.versions {
    background: #fff;
    padding: 60px 0 75px;
    border-bottom: solid 1px #000; }
    body.brutal-landing div.versions h3 {
      color: #000;
      font-size: 1.999em; }
      @media (max-width: 767px) {
        body.brutal-landing div.versions h3 {
          display: block;
          padding-bottom: 45px;
          margin-bottom: 45px;
          border-bottom: solid 1px #000; } }
    @media (max-width: 767px) {
      body.brutal-landing div.versions div.version-outer + div.version-outer {
        margin-top: 60px; } }
    body.brutal-landing div.versions h4 {
      margin-bottom: 5px;
      color: #000;
      font-weight: 300;
      text-transform: uppercase;
      text-align: left !important;
      font-size: 1.414em;
      display: block; }
    body.brutal-landing div.versions div.img-wrap {
      position: relative; }
      body.brutal-landing div.versions div.img-wrap:hover .btn-style-main {
        display: block; }
      body.brutal-landing div.versions div.img-wrap img {
        width: 100%;
        height: auto;
        outline: solid 1px #000; }
      body.brutal-landing div.versions div.img-wrap .btn-style-main {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100% !important;
        background-color: rgba(255, 255, 255, 0.95);
        height: 100%; }
        body.brutal-landing div.versions div.img-wrap .btn-style-main:hover span {
          outline: solid 1px #000 !important;
          filter: alpha(opacity=100);
          -moz-opacity: 1;
          -khtml-opacity: 1;
          opacity: 1; }
          body.brutal-landing div.versions div.img-wrap .btn-style-main:hover span:before {
            filter: alpha(opacity=100);
            -moz-opacity: 1;
            -khtml-opacity: 1;
            opacity: 1; }
        body.brutal-landing div.versions div.img-wrap .btn-style-main a, body.brutal-landing div.versions div.img-wrap .btn-style-main button {
          color: #000 !important;
          height: 100%; }
        body.brutal-landing div.versions div.img-wrap .btn-style-main span {
          height: 100%;
          outline: solid 1px #fff;
          background-color: transparent !important;
          color: #000 !important;
          position: relative;
          filter: alpha(opacity=0);
          -moz-opacity: 0;
          -khtml-opacity: 0;
          opacity: 0;
          -webkit-transition: opacity .1s ease-in-out;
          -moz-transition: opacity .1s ease-in-out;
          -o-transition: opacity .1s ease-in-out;
          transition: opacity .1s ease-in-out;
          -webkit-transition-delay: .30s;
          -moz-transition-delay: .30s;
          -o-transition-delay: .30s;
          transition-delay: .30s; }
          body.brutal-landing div.versions div.img-wrap .btn-style-main span:before {
            content: 'Check it out';
            position: absolute;
            top: 50%;
            left: 0;
            width: auto;
            margin: -23px auto;
            font-size: 16px;
            line-height: 16px;
            background-color: #000;
            color: #fff;
            padding: 15px 10px;
            display: inline-block;
            right: 0;
            max-width: 120px; }
            @media (max-width: 767px) {
              body.brutal-landing div.versions div.img-wrap .btn-style-main span:before {
                max-width: 150px; } }
            @media (min-width: 768px) and (max-width: 1024px) {
              body.brutal-landing div.versions div.img-wrap .btn-style-main span:before {
                max-width: 150px; } }
  body.brutal-landing div.versions.wp-coming-soon:hover {
    background: #000; }
    body.brutal-landing div.versions.wp-coming-soon:hover div.version-outer {
      background: #000; }
    body.brutal-landing div.versions.wp-coming-soon:hover div.img-wrap img {
      outline: none !important;
      -webkit-filter: invert(0);
      filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='invert'><feComponentTransfer><feFuncR type='linear' slope='-1' intercept='1'/><feFuncG type='linear' slope='-1' intercept='1' /><feFuncB type='linear' slope='-1' intercept='1' /></feComponentTransfer></filter></svg>#invert");
      filter: invert(0); }
    body.brutal-landing div.versions.wp-coming-soon:hover h4 {
      color: #fff; }
    body.brutal-landing div.versions.wp-coming-soon:hover .btn-style-main button {
      color: #fff; }
    body.brutal-landing div.versions.wp-coming-soon:hover .btn-style-main span {
      color: #fff; }
    body.brutal-landing div.versions.wp-coming-soon:hover .btn-style-main, body.brutal-landing div.versions.wp-coming-soon:hover .btn-style-main * {
      outline-color: #fff !important; }
    body.brutal-landing div.versions.wp-coming-soon:hover .btn-style-main:hover span {
      background: #fff;
      color: #000; }
  @media (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.versions.wp-coming-soon {
      background: #000; }
      body.brutal-landing div.versions.wp-coming-soon div.version-outer {
        background: #000; }
      body.brutal-landing div.versions.wp-coming-soon div.img-wrap img {
        outline: none !important;
        -webkit-filter: invert(0);
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='invert'><feComponentTransfer><feFuncR type='linear' slope='-1' intercept='1'/><feFuncG type='linear' slope='-1' intercept='1' /><feFuncB type='linear' slope='-1' intercept='1' /></feComponentTransfer></filter></svg>#invert");
        filter: invert(0); }
      body.brutal-landing div.versions.wp-coming-soon h4 {
        color: #fff; }
      body.brutal-landing div.versions.wp-coming-soon .btn-style-main button {
        color: #fff; }
      body.brutal-landing div.versions.wp-coming-soon .btn-style-main, body.brutal-landing div.versions.wp-coming-soon .btn-style-main * {
        outline-color: #fff !important; }
      body.brutal-landing div.versions.wp-coming-soon .btn-style-main:hover span {
        background: #fff;
        color: #000; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.versions.wp-coming-soon div.version-outer {
      padding: 0 15px !important; } }
  body.brutal-landing div.versions.wp-coming-soon h4 {
    font-size: 1.999em;
    font-weight: 300; }
    @media (max-width: 767px) {
      body.brutal-landing div.versions.wp-coming-soon h4 {
        font-size: 1.414em; }
        body.brutal-landing div.versions.wp-coming-soon h4 > span.stretch_it {
          white-space: nowrap;
          position: relative;
          left: -2px; } }
    body.brutal-landing div.versions.wp-coming-soon h4 + h4 {
      font-weight: 500;
      font-size: 2.827em;
      margin-bottom: 45px; }
      @media (max-width: 767px) {
        body.brutal-landing div.versions.wp-coming-soon h4 + h4 {
          font-size: 1.999em; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      body.brutal-landing div.versions.wp-coming-soon h4 {
        text-align: center !important; } }
  body.brutal-landing div.versions.wp-coming-soon div.img-wrap img {
    outline: none !important;
    -webkit-filter: invert(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='invert'><feComponentTransfer><feFuncR type='linear' slope='-1' intercept='1'/><feFuncG type='linear' slope='-1' intercept='1' /><feFuncB type='linear' slope='-1' intercept='1' /></feComponentTransfer></filter></svg>#invert");
    filter: invert(100%);
    width: 80%; }
    @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
      body.brutal-landing div.versions.wp-coming-soon div.img-wrap img {
        outline: none !important;
        -webkit-filter: invert(0);
        filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' ><filter id='invert'><feComponentTransfer><feFuncR type='linear' slope='-1' intercept='1'/><feFuncG type='linear' slope='-1' intercept='1' /><feFuncB type='linear' slope='-1' intercept='1' /></feComponentTransfer></filter></svg>#invert");
        filter: invert(0);
        width: 80%; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.versions.wp-coming-soon div.img-wrap {
      display: none; } }
  @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    body.brutal-landing div.versions.wp-coming-soon div.img-wrap {
      display: block !important; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    body.brutal-landing div.versions.wp-coming-soon div.img-wrap {
      top: 35px; } }
  body.brutal-landing div.versions.wp-coming-soon .btn-style-main {
    width: 100%;
    width: calc(100% - 2px);
    top: -1px;
    left: 1px;
    margin-top: 45px; }
    body.brutal-landing div.versions.wp-coming-soon .btn-style-main span {
      display: block;
      padding: 15px;
      text-align: center;
      width: 100%;
      height: auto;
      text-transform: uppercase;
      font-size: 1.24em; }
      @media (max-width: 767px) {
        body.brutal-landing div.versions.wp-coming-soon .btn-style-main span {
          color: #fff; } }
      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        body.brutal-landing div.versions.wp-coming-soon .btn-style-main span {
          color: #fff; } }
  body.brutal-landing div.versions.wp-coming-soon div.version-outer {
    padding-right: 30px; }
    body.brutal-landing div.versions.wp-coming-soon div.version-outer + div.version-outer {
      padding: 0 0 0 30px; }
    @media (max-width: 767px) {
      body.brutal-landing div.versions.wp-coming-soon div.version-outer {
        padding: 0 15px !important;
        margin-top: 0 !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      body.brutal-landing div.versions.wp-coming-soon div.version-outer {
        padding: 0 15px !important; } }
  body.brutal-landing div.versions.wp-coming-soon form.sub-form {
    padding: 0;
    border: none; }
    body.brutal-landing div.versions.wp-coming-soon form.sub-form span.sending, body.brutal-landing div.versions.wp-coming-soon form.sub-form span.sent {
      border: none;
      padding-top: 125px;
      color: #000; }
      @media (max-width: 767px) {
        body.brutal-landing div.versions.wp-coming-soon form.sub-form span.sending, body.brutal-landing div.versions.wp-coming-soon form.sub-form span.sent {
          padding-top: 115px; } }
      @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
        body.brutal-landing div.versions.wp-coming-soon form.sub-form span.sending, body.brutal-landing div.versions.wp-coming-soon form.sub-form span.sent {
          padding-top: 100px; } }
    body.brutal-landing div.versions.wp-coming-soon form.sub-form .form-group {
      margin-bottom: 0; }
    @media (max-width: 767px) {
      body.brutal-landing div.versions.wp-coming-soon form.sub-form {
        margin: 0; }
        body.brutal-landing div.versions.wp-coming-soon form.sub-form input {
          background: #000 !important;
          color: #fff !important;
          border-color: #fff !important; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      body.brutal-landing div.versions.wp-coming-soon form.sub-form input {
        background: #000 !important;
        color: #fff !important;
        border-color: #fff !important; } }
  body.brutal-landing div#customize-brutal {
    background: #fff; }
    body.brutal-landing div#customize-brutal h3 {
      color: #000; }
    body.brutal-landing div#customize-brutal li {
      color: #000; }
  body.brutal-landing div#spiel {
    background: #000; }
    body.brutal-landing div#spiel h4, body.brutal-landing div#spiel h5 {
      color: #fff;
      text-transform: none;
      display: block; }
    body.brutal-landing div#spiel h4 {
      margin-bottom: 15px;
      font-weight: 300; }
    body.brutal-landing div#spiel h5 {
      line-height: 32px; }
  body.brutal-landing div#stuff {
    padding: 30px 0;
    text-align: center; }
    body.brutal-landing div#stuff div.brutal-spec {
      margin: 5px;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      border: solid 1px #000;
      padding: 10px;
      position: relative; }
      body.brutal-landing div#stuff div.brutal-spec p, body.brutal-landing div#stuff div.brutal-spec h4 {
        position: relative;
        z-index: 2; }
      body.brutal-landing div#stuff div.brutal-spec:hover:before, body.brutal-landing div#stuff div.brutal-spec:hover:after {
        filter: alpha(opacity=100);
        -moz-opacity: 1;
        -khtml-opacity: 1;
        opacity: 1; }
      body.brutal-landing div#stuff div.brutal-spec:before, body.brutal-landing div#stuff div.brutal-spec:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: solid 1px #000;
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        opacity: 0;
        -webkit-transition: opacity .15s ease-in-out;
        -moz-transition: opacity .15s ease-in-out;
        -o-transition: opacity .15s ease-in-out;
        transition: opacity .15s ease-in-out; }
      body.brutal-landing div#stuff div.brutal-spec:before {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        top: 3px;
        left: 3px;
        -webkit-transition-delay: .15s;
        -moz-transition-delay: .15s;
        -o-transition-delay: .15s;
        transition-delay: .15s; }
      body.brutal-landing div#stuff div.brutal-spec:after {
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        top: -5px;
        left: -5px;
        -webkit-transition-delay: .30s;
        -moz-transition-delay: .30s;
        -o-transition-delay: .30s;
        transition-delay: .30s; }
      body.brutal-landing div#stuff div.brutal-spec.title-wrap {
        background: #000; }
        body.brutal-landing div#stuff div.brutal-spec.title-wrap:before {
          border: solid 1px #000;
          border-color: #fff !important; }
        body.brutal-landing div#stuff div.brutal-spec.title-wrap:after {
          border-color: #000 !important; }
        body.brutal-landing div#stuff div.brutal-spec.title-wrap h4 {
          font-weight: 300;
          text-transform: uppercase;
          font-size: 19px;
          color: #fff; }
  @media (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.brutal-support div#services h3 {
      display: block;
      margin: 0;
      padding: 60px 0 45px;
      margin-bottom: 45px;
      border-bottom: solid 1px #000;
      text-align: left;
      font-size: 1.999em; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.brutal-support div#services h3 {
      border-bottom: none;
      margin-bottom: 10px;
      padding-left: 0;
      text-align: center; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.brutal-support div#services h4 {
      font-weight: 300;
      font-size: 1.414em;
      text-transform: uppercase;
      margin-bottom: 5px;
      line-height: 1.2; } }
  @media (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.brutal-support div#services:hover div.services-center {
      background: #fff; }
      body.brutal-landing div.brutal-support div#services:hover div.services-center h3 {
        color: #000; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    body.brutal-landing div.brutal-support div#services div.services-left {
      height: 100%; } }
  body.brutal-landing div.brutal-support div#services div.services-left > div {
    border-left: none; }
  @media (max-width: 767px) {
    body.brutal-landing div.brutal-support div#services div.services-left > div, body.brutal-landing div.brutal-support div#services div.services-right > div {
      border: none;
      padding: 0 15px; } }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    body.brutal-landing div.brutal-support div#services div.services-left > div, body.brutal-landing div.brutal-support div#services div.services-right > div {
      border-right: none;
      padding: 30px; }
      body.brutal-landing div.brutal-support div#services div.services-left > div + div, body.brutal-landing div.brutal-support div#services div.services-right > div + div {
        border-left: solid 1px #000; } }
  @media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
    body.brutal-landing div.brutal-support div#services div.services-left > div, body.brutal-landing div.brutal-support div#services div.services-right > div {
      border-right: solid 1px #000; }
      body.brutal-landing div.brutal-support div#services div.services-left > div + div, body.brutal-landing div.brutal-support div#services div.services-right > div + div {
        border-left: transparent; } }
  body.brutal-landing div.brutal-support div#services div.services-right > div {
    border-right: none; }
    @media (max-width: 767px) {
      body.brutal-landing div.brutal-support div#services div.services-right > div + div {
        border-bottom: solid 1px #000; }
        body.brutal-landing div.brutal-support div#services div.services-right > div + div p {
          margin-bottom: 0;
          border-bottom: none; } }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      body.brutal-landing div.brutal-support div#services div.services-right > div {
        border-bottom: solid 1px #000; } }
  body.brutal-landing div.brutal-support div#services > div {
    border-top: none; }
  body.brutal-landing div.brutal-support div#services p {
    width: 100%;
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      body.brutal-landing div.brutal-support div#services p {
        margin-bottom: 60px;
        padding-bottom: 60px;
        border-bottom: solid 1px #000; } }
  body.brutal-landing div.brutal-support div#services a {
    vertical-align: bottom;
    overflow: visible; }
    body.brutal-landing div.brutal-support div#services a:after {
      display: none; }
    body.brutal-landing div.brutal-support div#services a:hover:before {
      height: 3px; }
    body.brutal-landing div.brutal-support div#services a:before {
      content: '';
      position: absolute;
      top: calc(100% - 2px);
      left: 0;
      width: 100%;
      height: 1px;
      background: #000;
      -webkit-transition: height .3s ease-in-out;
      transition: height .3s ease-in-out; }
  body.brutal-landing footer {
    background: #000; }
    body.brutal-landing footer a {
      color: #6c757d; }

div.style-toggle {
  position: fixed;
  bottom: 0;
  left: 0;
  width: auto;
  min-width: 71px;
  background: #fff;
  border: solid 1px #000;
  border-bottom: none;
  border-left: none;
  z-index: 100;
  padding: 15px;
  text-align: center; }
  @media (max-width: 767px) {
    div.style-toggle {
      min-width: 0;
      border: none;
      padding: 0; } }
  div.style-toggle strong.style-toggle-title {
    font-weight: lighter;
    display: inline-block;
    width: 100%; }
    @media (max-width: 767px) {
      div.style-toggle strong.style-toggle-title {
        display: none; }
        div.style-toggle strong.style-toggle-title + br {
          display: none; } }
  div.style-toggle span {
    display: inline-block;
    width: 34px;
    height: 34px;
    border: solid 1px #000;
    position: relative;
    margin-top: 8px;
    cursor: pointer; }
    @media (max-width: 767px) {
      div.style-toggle span {
        margin-top: 0;
        vertical-align: bottom; } }
    div.style-toggle span:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      top: 6px;
      left: 6px;
      background: #000;
      border-radius: 100%; }
